import { isValidValue } from '../..';
import renovationAddressHelper from '../../renovation-address-helper';
import { LABEL_INPUT_CITY, LABEL_INPUT_COUNTRY, LABEL_INPUT_POSTAL_CODE, LABEL_INPUT_STREET_NAME, LABEL_INPUT_STREET_NUMBER } from '../../form_labels';

export default function ({ required = true, requireStreetNumber = false, includeCadastralParcelNumber = true, requireSiret = false } = {}) {
    const addressPropertiesDescriptor = [
        {
            property: 'streetNumber',
            label: LABEL_INPUT_STREET_NUMBER,
            type: 'number',
            required: requireStreetNumber,
        },
        {
            property: 'streetName',
            type: 'string',
            label: LABEL_INPUT_STREET_NAME,
            required,
        },
        {
            property: 'city',
            type: 'string',
            label: LABEL_INPUT_CITY,
            required,
        },
        {
            property: 'postalCode',
            type: 'string',
            label: LABEL_INPUT_POSTAL_CODE,
            required,
        },
        {
            property: 'country',
            type: 'string',
            label: LABEL_INPUT_COUNTRY,
            required,
        },
        {
            property: 'siret',
            type: 'number',
            label: 'Siret de cet établissement',
            required: requireSiret,
        },
    ];
    if (includeCadastralParcelNumber) {
        addressPropertiesDescriptor.push({
            property: 'cadastralParcelNumber',
            type: 'string',
            label: 'Numéro de parcelle cadastrale',
            requiredIf: ({ streetNumber, postalCode }) => {
                if (postalCode && renovationAddressHelper.isPostalCodeInDomTom(postalCode)) return true;
                return !isValidValue(streetNumber);
            },
        });
    }

    return addressPropertiesDescriptor;
}
