export default {
    BAR_EN_101V_A33_3_CDP: 'BAR_EN_101V_A33_3_CDP',
    BAR_EN_101V_A33_3: 'BAR_EN_101V_A33_3',
    BAR_EN_101V_A39_4_CDP: 'BAR_EN_101V_A39_4_CDP',
    BAR_EN_101V_A39_4: 'BAR_EN_101V_A39_4',
    BAR_EN_102V_A14_1: 'BAR_EN_102V_A14_1',
    BAR_EN_102V_A39_2: 'BAR_EN_102V_A39_2',
    BAR_EN_103V_A36_4_CDP: 'BAR_EN_103V_A36_4_CDP',
    BAR_EN_103V_A36_4: 'BAR_EN_103V_A36_4',
    BAR_EN_103V_A39_5_CDP: 'BAR_EN_103V_A39_5_CDP',
    BAR_EN_103V_A39_5: 'BAR_EN_103V_A39_5',
    BAR_EN_104: 'BAR_EN_104V_A14_1',
    BAR_EN_107: 'BAR_EN_107V_A20_3',
    BAR_TH_104_CDP: 'BAR_TH_104V_A41_3_CDP',
    BAR_TH_104: 'BAR_TH_104V_A41_3',
    BAR_TH_106_CDP: 'BAR_TH_106V_A23_2_CDP',
    BAR_TH_106: 'BAR_TH_106V_A23_2',
    BAR_TH_127V_A36_3: 'BAR_TH_127V_A36_3',
    BAR_TH_148: 'BAR_TH_148V_A15_2',
    BAR_TH_159_CDP: 'BAR_TH_159V_A41_2_CDP',
    BAR_TH_159: 'BAR_TH_159V_A41_2',
    BAR_TH_160V_A27_1: 'BAR_TH_160V_A27_1',
    BAR_TH_160V_A39_2: 'BAR_TH_160V_A39_2',
    BAT_EN_101: 'BAT_EN_101V_A33_3',
    BAT_EN_102: 'BAT_EN_102V_A27_2',
    BAT_EN_103: 'BAT_EN_103V_A33_3',
    BAT_EN_106: 'BAT_EN_106V_A33_2',
    BAT_EN_108: 'BAT_EN_108V_A19_1',
    BAT_EN_109: 'BAT_EN_109V_A24_1',
    BAT_TH_146: 'BAT_TH_146V_A31_2',
    BAR_TH_101: 'BAR_TH_101V_A17_1',
    BAR_TH_129V_A27_3: 'BAR_TH_129V_A27_3',
    BAR_TH_143V_A25_1: 'BAR_TH_143V_A25_1',
    BAR_TH_143V_A25_1_CDP: 'BAR_TH_143V_A25_1_CDP',
    BAR_TH_143V_A51_5: 'BAR_TH_143V_A51_5',
    BAR_TH_143V_A51_5_CDP: 'BAR_TH_143V_A51_5_CDP',
    BAR_TH_112V_A35_2: 'BAR_TH_112V_A35_2',
    BAR_TH_112V_A35_2_CDP: 'BAR_TH_112V_A35_2_CDP',
    BAR_TH_112V_A46_3: 'BAR_TH_112V_A46_3',
    BAR_TH_112V_A46_3_CDP: 'BAR_TH_112V_A46_3_CDP',
    BAR_TH_113V_A41_3: 'BAR_TH_113V_A41_3',
    BAR_TH_113V_A41_3_CDP: 'BAR_TH_113V_A41_3_CDP',
    BAR_TH_159V_A44_3: 'BAR_TH_159V_A44_3',
    BAR_TH_159V_A44_3_CDP: 'BAR_TH_159V_A44_3_CDP',
    BAR_TH_125V_A36_3: 'BAR_TH_125V_A36_3',
};
