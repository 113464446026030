import { cloneDeep, isValidValue } from '.';
import {
    customerTypes,
    formModes,
    renovationAddressBuildingSectorsTranslations,
    renovationAddressHousingTypesTranslations,
    renovationAddressActivityAreasTranslations,
    renovationAddressBuildingSectors,
} from './enums';
import { LABEL_INPUT_ADDRESS_NAME, LABEL_INPUT_ADDRESS_NAME_OPTIONAL } from './form_labels';

function getAddressNameInputLabel(formData) {
    return {
        [customerTypes.CUSTOMER_PP]: LABEL_INPUT_ADDRESS_NAME_OPTIONAL,
        [customerTypes.CUSTOMER_PM]: LABEL_INPUT_ADDRESS_NAME,
    }[formData.customerType];
}

function canDisplayRenovationAddressIsHomeAddressCheckbox(formMode, formData) {
    const { customerType, homeAddressAlreadySetAsRenovationAddress = false } = formData;

    return formMode === formModes.CREATION && customerType === customerTypes.CUSTOMER_PP && !homeAddressAlreadySetAsRenovationAddress;
}

function canDisplayHomeAddressInformationForm(formState) {
    return isHomeAddress(formState);
}

function isHomeAddress(formState) {
    const { homeAddress = false } = formState;

    return homeAddress;
}

function getAddressFormState(formMode, formState, formData) {
    const { homeRenovationAddress = {} } = formData;

    return {
        [formModes.CREATION]: homeRenovationAddress,
        [formModes.EDITION]: formState,
    }[formMode];
}

function getHomeAddressCadastralParcelNumber(formMode, formState, formData) {
    const { homeRenovationAddress = {} } = formData;

    return {
        [formModes.CREATION]: homeRenovationAddress.cadastralParcelNumber,
        [formModes.EDITION]: formState.cadastralParcelNumber,
    }[formMode];
}

function canDisplayAddressFinder(formState) {
    return !isHomeAddress(formState);
}

function canDisplayPhoneInput(formData) {
    return isCustomerPM(formData);
}

function canDisplayBuildingSectorSelect(formData) {
    return isCustomerPM(formData);
}

function canDisplayHousingTypeSelect(formState) {
    return isResidentialArea(formState);
}

function canDisplayActivityAreaSelect(formState) {
    return isTertiaryArea(formState);
}

function isCustomerPM(formData) {
    return formData.customerType === customerTypes.CUSTOMER_PM;
}

function isCustomerPP(formData) {
    return formData.customerType === customerTypes.CUSTOMER_PP;
}

function isResidentialArea(formState) {
    return formState.buildingSector === renovationAddressBuildingSectors.RESIDENTIAL;
}

function isTertiaryArea(formState) {
    return formState.buildingSector === renovationAddressBuildingSectors.TERTIARY;
}

function translateBuildingSector(formState) {
    return renovationAddressBuildingSectorsTranslations[formState.buildingSector];
}

function translateHousingType(formState) {
    return renovationAddressHousingTypesTranslations[formState.housingType];
}

function translateActivityArea(formState) {
    return renovationAddressActivityAreasTranslations[formState.activityArea];
}

function extractAddressInformation({ streetNumber, streetName, city, postalCode, country, cadastralParcelNumber } = {}) {
    return { streetNumber, streetName, city, postalCode, country, cadastralParcelNumber };
}

function doesRenovationAddressesIncludeHomeAddress(renovationAddresses = []) {
    return isValidValue(renovationAddresses.find(({ homeAddress }) => homeAddress));
}

function updateFormStateUsingBuilingSector(formState = {}) {
    const updatedFormState = cloneDeep(formState);
    const updatingStrategies = {
        [renovationAddressBuildingSectors.TERTIARY]: () => {
            delete updatedFormState.housingType;
        },
        [renovationAddressBuildingSectors.RESIDENTIAL]: () => {
            delete updatedFormState.activityArea;
        },
    };
    const chosenStrategy = updatingStrategies[updatedFormState.buildingSector];
    if (chosenStrategy) chosenStrategy();

    return updatedFormState;
}

function canDisplayIsHouseOwnerCheckbox(formData = {}, { buildingSector }) {
    return isCustomerPP(formData) && buildingSector === renovationAddressBuildingSectors.RESIDENTIAL;
}

function canDisplayCustomerBonifications(renovationAddress) {
    return renovationAddress.country.toLowerCase() === 'france';
}

export default {
    getAddressNameInputLabel,
    canDisplayRenovationAddressIsHomeAddressCheckbox,
    canDisplayHomeAddressInformationForm,
    getAddressFormState,
    getHomeAddressCadastralParcelNumber,
    canDisplayAddressFinder,
    canDisplayPhoneInput,
    canDisplayBuildingSectorSelect,
    canDisplayHousingTypeSelect,
    canDisplayActivityAreaSelect,
    translateBuildingSector,
    translateHousingType,
    translateActivityArea,
    extractAddressInformation,
    doesRenovationAddressesIncludeHomeAddress,
    isCustomerPM,
    isCustomerPP,
    isResidentialArea,
    isTertiaryArea,
    updateFormStateUsingBuilingSector,
    canDisplayIsHouseOwnerCheckbox,
    canDisplayCustomerBonifications,
};
