export const LABEL_INPUT_INSURANCE_NAME = "Raison sociale de l'assureur";
export const LABEL_INPUT_INSURANCE_REFERENCE = "Numéro du contrat d'assurance";
export const LABEL_INPUT_INSURANCE_ADDRESS = "Adresse du siège social de l'assureur";
export const LABEL_INPUT_INSURANCE_WARRANTY_AREA = 'Couverture de la garantie';
export const LABEL_INPUT_INSURANCE_START_DATE = "Date de début d'application";

export const MODAL_TITLE_INSURANCE = 'Ajouter une attestation d’assurance décennale';
export const MODAL_TITLE_INVITATION = 'Inviter vos collaborateurs';

export const LABEL_TAB_INSURANCES = 'Assurance décennale';
export const LABEL_TAB_ACCOUNTS = 'Utilisateurs';

export const BLOCK_TITLE_ACCOUNTS = 'Tous les comptes utilisateurs';

export const LABEL_BUTTON_UPDATE_PAYMENT_INFORMATION = 'Mettre à jour mes informations de paiement';
export const LABEL_BUTTON_INVITATION = 'Inviter un collaborateur';

export const LABEL_MENU_INVITATIONS = 'Menu invitations';

export const LABEL_BUTTON_REFRESH_ORGANIZATION_INFORMATION = 'Mettre à jour les informations';

export const LABEL_COMPANY_NAME = 'Dénomination';
export const LABEL_SIREN = 'SIREN';
export const LABEL_SIRET = 'SIRET';
export const LABEL_COMPANY_TYPE = 'Forme juridique';
export const LABEL_VAT_NUMBER = 'N° TVA';
export const LABEL_NAF_CODE = 'Code NAF';
export const LABEL_COMPANY_WEBSITE = 'Site web';
export const LABEL_COMPANY_PHONE = 'Téléphone';
