export const LABEL_TAB_ALL_INVOICES = 'Toutes les factures';
export const LABEL_TAB_FINALIZED_INVOICES = 'Factures à envoyer';
export const LABEL_TAB_SENT_INVOICES = 'Factures à relancer';

export const LABEL_EXPORT_INVOICES_PREFIX = 'EXPORT_RENOLIB_FACTURES_';

export const LABEL_INPUT_INVOICE_ISSUE_DATE = 'Date de la facture';
export const LABEL_INSTALLATION_START_DATE = "Date d'installation";
export const LABEL_INSTALLATION_END_DATE = "Date de fin d'installation";

export const LABEL_BUTTON_FINALIZE_INVOICE = 'FINALISER LA FACTURE';
export const LABEL_BUTTON_SAVE_INVOICE_DRAFT = 'Enregistrer le brouillon';

export const LABEL_BACK_TO_INVOICE_EDITION_PAGE = 'Retourner à la facture en cours de saisie';

export const LABEL_BUTTON_CREATE_INVOICE = 'CRÉER UNE FACTURE';

export const LABEL_SELECT_QUOTATION_FOR_INVOICE = 'Sélectionner le devis à facturer';

export const MODAL_TITLE_CREATE_DELEGATEE_INVOICE = 'Créer une facture obligé';

export const LABEL_AUTOCOMPLETE_DELEGATEE = "Sélectionner l'obligé à facturer";

export const LABEL_BUTTON_ADD_BILLING_NOTE = "Ajouter l'appel à facturation reçu";

export const LABEL_CREATE_ADVANCE_PAYMENT_INVOICE = "Créer une facture d'acompte";
export const LABEL_ADVANCE_PAYMENT_INVOICE_ALREADY_EXISTS = "Une facture d'acompte (du montant total de l'acompte précisé sur le devis) a déjà été créée";
