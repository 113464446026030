export default {
    primary: '#1d3495',
    primaryLight0: '#E5E8F3',
    primaryLight1: '#1d34951c',
    primaryLight2: '#4c93e3',
    primaryLight3: '#0026c8',
    primaryDark: '#02197a',
    primaryDark0: '#1d3495c0',
    primaryDark1: '#1d3495c0',
    primaryDark2: '#176ac7',
    primaryDark3: '#001c96',
    primaryOutlineLight: '#1D349519',
    primaryOverlay: 'rgba(29, 44, 107, 0.75)',
    secondary: '#00928f',
    label: '#74798c',
    secondaryLight1: '#31aeac33',
    secondaryLight2: '#03828035',
    secondaryLight3: '#31aeac',
    secondaryDark: '#097877',
    labelDark: '#097877',
    secondaryDark1: '#31aeacf8',
    secondaryDark2: '#038280ca',
    light: '#ffffff',
    lightDark: '#ffffff',
    lightGray: '#A0A5BA',
    black: '#000000',
    white: '#ffffff',
    quotationBlocksBorder: '#33f1e6',
    green: '#4ebc9d',
    red: '#e46526',
    warning: '#e2b844',
    tableBlock: '#f8f8f8',
    tableBorder: '#9ea5c1',
    error: '#dd2c00',
    noValidated: '#eaeaea',
    pending: '#e2b844',
    confirmed: '#31aeac',
    rejected: '#e6743c',
    container: '#f5f6fa',
    divider: '#d6dce9',
    icon: '#90b1e1',
    disabled: '#c5c5c5',
    shadow: {
        primary: '#90b1e161',
    },
    scrollbar: {
        thumb: {
            default: '#99a3ac',
        },
        thumbHover: {
            default: '#b30000',
        },
    },
    product: {
        status: { PENDING: '#e2b844', CONFIRMED: '#31aeac', UNVERIFIED: '#74798C1A' },
    },
    input: {
        label: '#74798c',
        placeholder: '#99a3ac',
        border: '#e4e4e4',
    },
};
