export const DIFFERENT_PASSWORDS = 'Vos mots de passe ne sont pas identiques';

export const OBLIGATORY_CONFIRM_PASSWORD = 'Confirmation du mot de passe obligatoire';

export const UNAUTHORIZED_EMAIL = 'Adresse email non autorisée';

export const INVALID_ADDRESS = "Nous n'avons pas pu récupérer toutes les informations concernant l'adresse. Veuillez renseigner les informations manquantes.";

export const MUST_PROVIDE_AT_LEAT_ONE_PHONE_NUMBER = 'Vous devez renseigner au moins un numéro de téléphone';

export const PHONE_LENGTH_MUST_BE_VALID = 'Votre numéro de téléphone doit être composé de 10 chiffres';

export const TABLE_COLUMN_START_DATE = 'Date de début';
export const TABLE_COLUMN_END_DATE = 'Date de fin';

export const TOTAL_PRE_TAX_WITH_COMMERCIAL_DISCOUNT = 'Montant total HT après remise';

export const NOT_SPECIFIED = 'Non renseigné';
export const TABLE_CREATED_BY_TITLE = 'Utilisateurs';

export const YOUR_REQUEST_HAS_BEEN_RECEIVED = 'Votre demande a bien été prise en compte.';
export const YOUR_CHOICE_HAS_BEEN_RECEIVED = 'Votre choix a bien été pris en compte.';

export const ADVANCE_PAYMENT_AGREEMENT_MESSAGE =
    "Votre dossier est maintenant complété.<br />Souhaitez-vous bénéficier d'une avance de prime CEE payée sous 10 jours ouvrés à compter de la réception de votre dossier complet ?";
