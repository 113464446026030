import React, { useEffect } from 'react';
import { BrowserRouter, Router, Switch, Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { useSelector, useDispatch } from 'react-redux';

import history from '../utils/history';
import { publicRoutes } from '../routes';
import formActions from '../store/actions/ui/form';
import applicationConfigurationActions from '../store/actions/application-configuration.actions';
import applicationConfigurationHelper from '../utils/application-configuration.helper';

import TypedRoute from './helpers/TypedRoute';
import Maintenance from './pages/Maintenance';

const Layout = loadable(() => import('./layout/Layout'));

export default function App() {
    const dispatch = useDispatch();
    const { errorMessage } = useSelector((state) => state.ui.form);
    const { applicationConfiguration } = useSelector((state) => state.applicationConfigurationState);

    useEffect(() => {
        const clickListener = document.addEventListener('click', () => {
            if (errorMessage) dispatch(formActions.resetForm());
        });

        return () => document.removeEventListener('click', clickListener);
    }, [errorMessage, dispatch]);

    useEffect(() => {
        dispatch(applicationConfigurationActions.getActiveApplicationConfiguration());
    }, [dispatch]);

    return (
        <BrowserRouter>
            <Router history={history}>
                {applicationConfigurationHelper.isApplicationInMaintenanceMode(applicationConfiguration) ? (
                    <Switch>
                        <Route exact path='/maintenance' component={Maintenance} />
                        <Route path='/' render={() => <Redirect to='/maintenance' />} />
                    </Switch>
                ) : (
                    <Switch>
                        {publicRoutes.map((publicRoute, index) => (
                            <TypedRoute type='public' key={index} path={publicRoute.path} component={publicRoute.component} exact={!!publicRoute.exact} />
                        ))}
                        <Route path='/' component={Layout} />
                    </Switch>
                )}
            </Router>
        </BrowserRouter>
    );
}
