import {
    CREATE_CUSTOMER_PP_REQUEST,
    CREATE_CUSTOMER_PP_SUCCESS,
    CREATE_CUSTOMER_PP_FAILURE,
    GET_CUSTOMER_PP_REQUEST,
    GET_CUSTOMER_PP_SUCCESS,
    GET_CUSTOMER_PP_FAILURE,
    GET_CUSTOMERS_PP_REQUEST,
    GET_CUSTOMERS_PP_SUCCESS,
    GET_CUSTOMERS_PP_FAILURE,
    UPDATE_CUSTOMER_PP_REQUEST,
    UPDATE_CUSTOMER_PP_SUCCESS,
    UPDATE_CUSTOMER_PP_FAILURE,
    DELETE_CUSTOMER_PP_REQUEST,
    DELETE_CUSTOMER_PP_SUCCESS,
    DELETE_CUSTOMER_PP_FAILURE,
} from '../types/customer-pp.types';
import customerPPService from '../services/customer-pp.service';
import { parseError } from '../../api';
import formActions from './ui/form';
import dialogsActions from './ui/dialogs';
import history from '../../utils/history';
import { CUSTOMER_CREATED, CUSTOMER_UPDATED } from '../../utils/messages';

function createCustomerPP({ customerPPData, onSuccess } = {}) {
    return (dispatch) => {
        dispatch({ type: CREATE_CUSTOMER_PP_REQUEST });
        dispatch(formActions.startRequesting());

        customerPPService
            .createCustomerPP(customerPPData)
            .then(({ data }) => {
                onSuccess();
                dispatch({ type: CREATE_CUSTOMER_PP_SUCCESS, payload: { customerPP: data } });
                dispatch(formActions.stopRequesting());
                history.push(`clients/${data.uuid}?type=particulier`);
                dispatch(dialogsActions.showSuccessDialog({ message: CUSTOMER_CREATED }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: CREATE_CUSTOMER_PP_FAILURE, payload: { errorMessage } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function getCustomerPP({ customerPPUUID } = {}) {
    return (dispatch) => {
        dispatch({ type: GET_CUSTOMER_PP_REQUEST });

        customerPPService
            .getCustomerPP(customerPPUUID)
            .then(({ data }) => {
                dispatch({ type: GET_CUSTOMER_PP_SUCCESS, payload: { customerPP: data } });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: GET_CUSTOMER_PP_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage, redirect: '/clients' }));
            });
    };
}

function getCustomersPP() {
    return (dispatch) => {
        dispatch({ type: GET_CUSTOMERS_PP_REQUEST, payload: [] });

        customerPPService
            .getCustomersPP()
            .then(({ data }) => {
                dispatch({ type: GET_CUSTOMERS_PP_SUCCESS, payload: { customersPP: data } });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: GET_CUSTOMERS_PP_FAILURE, payload: { errorMessage } });
            });
    };
}

function updateCustomerPP({ customerPPUUID, customerPPUpdateData, onSuccess, successMessage = CUSTOMER_UPDATED } = {}) {
    return (dispatch) => {
        dispatch({ type: UPDATE_CUSTOMER_PP_REQUEST });
        dispatch(formActions.startRequesting());

        customerPPService
            .updateCustomerPP(customerPPUUID, customerPPUpdateData)
            .then(({ data }) => {
                onSuccess();
                dispatch({ type: UPDATE_CUSTOMER_PP_SUCCESS, payload: { customerPP: data } });
                dispatch(formActions.stopRequesting());
                dispatch(getCustomerPP({ customerPPUUID }));
                dispatch(dialogsActions.showSuccessDialog({ message: successMessage }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: UPDATE_CUSTOMER_PP_FAILURE, payload: { errorMessage } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function deleteCustomerPP({ customerPPUUID } = {}) {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_CUSTOMER_PP_REQUEST });
        dispatch(formActions.startRequesting());
        const accountUUID = getState().auth.account.uuid;

        customerPPService
            .deleteCustomerPP(customerPPUUID, accountUUID)
            .then(({ data }) => {
                dispatch({ type: DELETE_CUSTOMER_PP_SUCCESS, payload: { customerPP: data } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: 'Le compte client a bien été supprimé' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                dispatch({ type: DELETE_CUSTOMER_PP_FAILURE, payload: { errorMessage } });
            });
    };
}

export default {
    createCustomerPP,
    getCustomerPP,
    getCustomersPP,
    updateCustomerPP,
    deleteCustomerPP,
};
