import { operationInstallationTypes } from '../../enums';
import { LABEL_SELECT_INSTALLATION_TYPE } from '../../form_labels';
import {
    LABEL_MODELE_VENTILATION,
    LABEL_TYPE_VENTILATION,
    LABEL_CLASSE_ENERGETIQUE_VENTILATION,
    LABEL_REFERENCE_AVIS_TECHNIQUE,
    LABEL_DATE_VALIDITE_AVIS_TECHNIQUE,
    LABEL_PUISSANCE_ELECTRIQUE,
    LABEL_TYPE_CAISSON,
    LABEL_MARQUE_CAISSON,
    LABEL_REFERENCE_CAISSON,
    LABEL_MARQUE_BOUCHES_EXTRACTION,
    LABEL_REFERENCE_BOUCHES_EXTRACTION,
    LABEL_SELECT_VENTILATION_ENERGY_CLASS_1254_2014,
    LABEL_INPUT_EXCHANGER_THERMAL_EFFICIENCY,
    LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE,
    LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE,
    LABEL_INPUT_EXCHANGER_STATIC_EFFICIENCY,
    LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_BRAND,
    LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_REFERENCE,
    LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_BRAND,
    LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_REFERENCE,
} from '../../product-constants';

export default {
    simpleFlowVentilation: [
        {
            property: LABEL_MODELE_VENTILATION,
            label: 'Modèle de ventilation',
            type: 'string',
            required: true,
        },
        {
            property: LABEL_TYPE_VENTILATION,
            label: 'Type',
            type: 'string',
            required: true,
        },
        {
            property: LABEL_CLASSE_ENERGETIQUE_VENTILATION,
            label: 'Classe énergétique',
            type: 'string',
            required: true,
        },
        {
            property: LABEL_REFERENCE_AVIS_TECHNIQUE,
            label: "Référence de l'avis technique",
            type: 'string',
            required: true,
        },
        {
            property: LABEL_DATE_VALIDITE_AVIS_TECHNIQUE,
            label: "Date de validité de l'avis technique",
            type: 'string',
            required: true,
        },
        {
            property: LABEL_PUISSANCE_ELECTRIQUE,
            label: 'Puissance électrique',
            type: 'number',
            required: true,
        },
        {
            property: LABEL_TYPE_CAISSON,
            label: 'Type de caisson',
            type: 'string',
            required: true,
        },
        {
            property: LABEL_MARQUE_CAISSON,
            label: 'Marque du caisson',
            type: 'string',
            required: true,
        },
        {
            property: LABEL_REFERENCE_CAISSON,
            label: 'Référence du caisson',
            type: 'string',
            required: true,
        },
        {
            property: LABEL_MARQUE_BOUCHES_EXTRACTION,
            label: "Marque des bouches d'extraction",
            type: 'string',
            required: true,
        },
        {
            property: LABEL_REFERENCE_BOUCHES_EXTRACTION,
            label: "Référence des bouches d'extraction",
            type: 'string',
            required: true,
        },
    ],
    doubleFlowVentilation: [
        {
            property: 'installationType',
            label: LABEL_SELECT_INSTALLATION_TYPE,
            type: 'string',
            required: true,
        },
        {
            property: LABEL_SELECT_VENTILATION_ENERGY_CLASS_1254_2014,
            label: LABEL_SELECT_VENTILATION_ENERGY_CLASS_1254_2014,
            type: 'string',
            requiredIf: isIndividualInstallation,
        },
        {
            property: LABEL_INPUT_EXCHANGER_THERMAL_EFFICIENCY,
            label: LABEL_INPUT_EXCHANGER_THERMAL_EFFICIENCY,
            type: 'number',
            requiredIf: isIndividualInstallation,
        },
        {
            property: LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE,
            label: LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE,
            type: 'string',
            requiredIf: isCollectiveInstallation,
        },
        {
            property: LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE,
            label: LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE,
            type: 'string',
            requiredIf: isCollectiveInstallation,
        },
        {
            property: LABEL_INPUT_EXCHANGER_STATIC_EFFICIENCY,
            label: LABEL_INPUT_EXCHANGER_STATIC_EFFICIENCY,
            type: 'number',
            requiredIf: isCollectiveInstallation,
        },
        {
            property: LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_BRAND,
            label: LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_BRAND,
            type: 'string',
            required: true,
        },
        {
            property: LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_REFERENCE,
            label: LABEL_INPUT_DOUBLE_FLOW_VENTILATION_BOX_REFERENCE,
            type: 'string',
            required: true,
        },
        {
            property: LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_BRAND,
            label: LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_BRAND,
            type: 'string',
            required: true,
        },
        {
            property: LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_REFERENCE,
            label: LABEL_INPUT_SELF_ADJUSTING_EXTRACTION_VENT_REFERENCE,
            type: 'string',
            required: true,
        },
    ],
};

function isIndividualInstallation(formState) {
    return [operationInstallationTypes.INDIVIDUAL_INSTALLATION_SELF_ADJUSTING, operationInstallationTypes.INDIVIDUAL_INSTALLATION_MODULATED].includes(formState.installationType);
}

function isCollectiveInstallation(formState) {
    return [operationInstallationTypes.COLLECTIVE_INSTALLATION_SELF_ADJUSTING].includes(formState.installationType);
}
