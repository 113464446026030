import {
    productTypes,
    productUnitsLabelEnum,
    ventilationTypesLabels,
    heatPumpTypesLabel,
    productStatuses,
    rackingProfilesLabels,
    functioningAlimentationTypesLabel,
    productSubCategoriesTranslations,
} from './enums';
import operationSheetNames from './operation-sheet-names';
import { productCategoriesValues } from './enums';
import {
    LABEL_TYPE_VENTILATION,
    LABEL_TYPE_FONCTIONNEMENT,
    LABEL_LA_POMPE_A_CHALEUR_EST_DE_TYPE_AIR_EAU,
    LABEL_DATE_VALIDITE_AVIS_TECHNIQUE,
    HYBRID_SOLAR_CAPTORS,
    LABEL_NATURE_FLUID_CAPTORS,
    SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER,
    LABEL_RACKING_PROFILE,
    LABEL_GREEN_FLAME_LABEL_SEVEN,
    LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE,
    LABEL_OLD_OR_NEW_SILO_OF_AT_LEAST_225_LITERS_PRESENCE,
    LABEL_OLD_OR_NEW_BUFFER_TANK_PRESENCE,
    LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE,
    LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE,
} from './product-constants';
import entitiesUtils from './entities-utils';
import { OPTIONS_NATURE_FLUID_TRANSLATION } from './translation';
import { LABEL_INPUT_CERTIFICATION, LABEL_INPUT_CERTIFICATION_ACERMI, SOLAR_PRODUCT_CERTIFICATION_LABEL } from './form_labels';

export const productCategoryOperations = {
    ISOLANT: [
        'BAR_EN_101V_A33_3',
        'BAR_EN_101V_A33_3_CDP',
        'BAR_EN_101V_A39_4',
        'BAR_EN_101V_A39_4_CDP',
        'BAR_EN_102V_A14_1',
        'BAR_EN_102V_A39_2',
        operationSheetNames.BAR_EN_103V_A36_4,
        operationSheetNames.BAR_EN_103V_A36_4_CDP,
        operationSheetNames.BAR_EN_103V_A39_5,
        operationSheetNames.BAR_EN_103V_A39_5_CDP,
        'BAR_EN_106V_A33_4',
        'BAR_EN_107V_A20_3',
        'BAT_EN_101V_A33_3',
        'BAT_EN_102V_A27_2',
        'BAT_EN_103V_A33_3',
        'BAT_EN_106V_A33_2',
        'BAT_EN_108V_A19_1',
    ],
    POMPE_CHALEUR: [
        'BAR_TH_104V_A23_2',
        'BAR_TH_104V_A23_2_CDP',
        'BAR_TH_104V_A41_3',
        'BAR_TH_104V_A41_3_CDP',
        'BAR_TH_159V_A26_1',
        'BAR_TH_159V_A26_1_CDP',
        'BAR_TH_159V_A41_2',
        'BAR_TH_159V_A41_2_CDP',
        operationSheetNames.BAR_TH_129V_A27_3,
        operationSheetNames.BAR_TH_159V_A44_3,
        operationSheetNames.BAR_TH_159V_A44_3_CDP,
    ],
    TOITURE: ['BAT_EN_109V_A24_1'],
    FENETRE: ['BAR_EN_104V_A14_1'],
    CHAUDIERE_INDIVIDUELLE: ['BAR_TH_106V_A23_2'],
    VENTILATION: [operationSheetNames.BAR_TH_127V_A36_3, operationSheetNames.BAR_TH_125V_A36_3],
    CALORIFUGEAGE: [operationSheetNames.BAR_TH_160V_A27_1, operationSheetNames.BAR_TH_160V_A39_2, operationSheetNames.BAT_TH_146],
    CHAUFFE_EAU_THERMODYNAMIQUE: [operationSheetNames.BAR_TH_148],
    CHAUFFE_EAU_SOLAIRE_INDIVIDUEL: [operationSheetNames.BAR_TH_101],
    SYSTEME_SOLAIRE_COMBINE: [operationSheetNames.BAR_TH_143V_A25_1, operationSheetNames.BAR_TH_143V_A25_1_CDP, operationSheetNames.BAR_TH_143V_A51_5, operationSheetNames.BAR_TH_143V_A51_5_CDP],
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: [
        operationSheetNames.BAR_TH_112V_A35_2,
        operationSheetNames.BAR_TH_112V_A35_2_CDP,
        operationSheetNames.BAR_TH_112V_A46_3,
        operationSheetNames.BAR_TH_112V_A46_3_CDP,
    ],
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: [operationSheetNames.BAR_TH_113V_A41_3, operationSheetNames.BAR_TH_113V_A41_3_CDP],
};

export const isAProductCategoryWithCertificationCSTBat = (productCategory) =>
    [productCategoriesValues.SYSTEME_SOLAIRE_COMBINE, productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL].includes(productCategory);

function getMainProduct(products = []) {
    return products.find((quotationProduct) => quotationProduct?.product?.type === productTypes.MAIN_PRODUCT);
}

function filterProducts(products = [], type) {
    return products.filter(({ product }) => product?.type === type);
}

function filterMainProducts(products) {
    return filterProducts(products, productTypes.MAIN_PRODUCT);
}

function filterOtherProducts(products) {
    return filterProducts(products, productTypes.OTHER_PRODUCT);
}

function getProductCategory(operationSheetName) {
    const foundCategories = Object.keys(productCategoryOperations).filter((category) => productCategoryOperations[category].includes(operationSheetName));

    return foundCategories[0];
}

function getProductBlockTitle(operationSheetName, { product = {} }) {
    const text = 'Produit';

    if (product?.subCategory) return text.concat(` : ${productSubCategoriesTranslations[product.subCategory]}`);

    return text.concat(operationSheetName ? ` : ${getProductCategoryLabel(operationSheetName)}` : '');
}

const getUnitLabel = (unit) => {
    return productUnitsLabelEnum[unit];
};

function getProductCategoryLabel(operationSheetName) {
    return {
        [productCategoriesValues.ISOLANT]: 'Isolant',
        [productCategoriesValues.FENETRE]: 'Fenêtre',
        [productCategoriesValues.CHAUDIERE_INDIVIDUELLE]: 'Chaudière individuelle',
        [productCategoriesValues.VENTILATION]: 'Ventilation',
        [productCategoriesValues.POMPE_CHALEUR]: 'Pompe à chaleur',
        [productCategoriesValues.CALORIFUGEAGE]: 'Calorifuge',
        [productCategoriesValues.TOITURE]: 'Toiture',
        [productCategoriesValues.CHAUFFE_EAU_THERMODYNAMIQUE]: 'Chauffe-Eau Thermodynamique',
        [productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL]: 'Chauffe-Eau Solaire Individuel',
        [productCategoriesValues.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS]: 'Appareil indépendant de chauffage au bois',
        [productCategoriesValues.SYSTEME_SOLAIRE_COMBINE]: 'Systeme solaire combiné',
        [productCategoriesValues.CHAUDIERE_BIOMASSE_INDIVIDUELLE]: 'Chaudiere biomasse individuelle',
    }[getProductCategory(operationSheetName)];
}

function areProductPropertiesValid(product, productType) {
    const requiredProperties = {
        [productTypes.MAIN_PRODUCT]: ['title', 'brand', 'reference'],
        [productTypes.OTHER_PRODUCT]: ['title'],
    };
    const providedProperties = Object.keys(product);

    return requiredProperties[productType].every((property) => providedProperties.includes(property));
}

function isAMainProduct(product) {
    return product?.type === productTypes.MAIN_PRODUCT;
}

function getProductDetailToSpecifyValue(productDetailToSpecify) {
    const strategies = {
        [LABEL_TYPE_VENTILATION]: getValueLabel,
        [LABEL_TYPE_FONCTIONNEMENT]: getValueLabel,
        [LABEL_LA_POMPE_A_CHALEUR_EST_DE_TYPE_AIR_EAU]: getValueLabel,
        [LABEL_DATE_VALIDITE_AVIS_TECHNIQUE]: formatValueToDate,
        [HYBRID_SOLAR_CAPTORS]: getValueLabel,
        [LABEL_NATURE_FLUID_CAPTORS]: getNaturalFluidValueTranslation,
        [LABEL_RACKING_PROFILE]: getRackingProfileValueLabel,
        [LABEL_GREEN_FLAME_LABEL_SEVEN]: getValueLabel,
        [SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER]: getValueLabel,
        [LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE]: getValueLabel,
        [LABEL_OLD_OR_NEW_SILO_OF_AT_LEAST_225_LITERS_PRESENCE]: getValueLabel,
        [LABEL_OLD_OR_NEW_BUFFER_TANK_PRESENCE]: getValueLabel,
        [LABEL_RADIO_GROUP_DOUBLE_FLOW_BOX_IS_COLLECTIVE]: getValueLabel,
        [LABEL_RADIO_GROUP_STATIC_EXCHANGER_IS_COLLECTIVE]: getValueLabel,
    };

    return Object.keys(strategies).includes(productDetailToSpecify.label) ? strategies[productDetailToSpecify.label](productDetailToSpecify.value) : productDetailToSpecify.value;
}

function getProductExclusionDetailToSpecifyValue(productExclusionDetailToSpecify) {
    const strategies = {
        [HYBRID_SOLAR_CAPTORS]: getValueLabel,
    };
    return Object.keys(strategies).includes(productExclusionDetailToSpecify.label)
        ? strategies[productExclusionDetailToSpecify.label](productExclusionDetailToSpecify.value)
        : productExclusionDetailToSpecify.value;
}
function getValueLabel(productDetailToSpecifyValue) {
    const possibleValues = {
        ...ventilationTypesLabels,
        ...heatPumpTypesLabel,
        ...functioningAlimentationTypesLabel,
        true: 'Oui',
        false: 'Non',
    };

    return possibleValues[productDetailToSpecifyValue];
}

function getRackingProfileValueLabel(rackingProfileValue) {
    return rackingProfilesLabels[rackingProfileValue];
}

function getNaturalFluidValueTranslation(naturalFluidLabel) {
    return Object.keys(OPTIONS_NATURE_FLUID_TRANSLATION).includes(naturalFluidLabel) ? OPTIONS_NATURE_FLUID_TRANSLATION[naturalFluidLabel] : '';
}

function formatValueToDate(productDetailToSpecifyValue) {
    return entitiesUtils.formatDate(productDetailToSpecifyValue);
}

function productExclusionDetailsToSpecifyExist(detailsPerOperation) {
    return detailsPerOperation?.details?.productExclusionDetailsToSpecify?.length > 0;
}

function productDetailsToSpecifyExist(detailsPerOperation) {
    return detailsPerOperation?.details?.productDetailsToSpecify?.length > 0;
}

function productFilesExist(productFiles) {
    return productFiles.length > 0;
}

function getCertificationWording(product) {
    if (isAProductCategoryWithCertificationCSTBat(product.category)) return SOLAR_PRODUCT_CERTIFICATION_LABEL;
    return [productCategoriesValues.ISOLANT, productCategoriesValues.CALORIFUGEAGE].includes(product.category) ? LABEL_INPUT_CERTIFICATION_ACERMI : LABEL_INPUT_CERTIFICATION;
}

function getProductOrFormatToFormData(product) {
    const formData = new FormData();

    if (product?.file) {
        formData.append('file', product.file);
        delete product.file;
    }

    formData.append('technicalSheetFile', product.technicalSheetFile);
    delete product.technicalSheetFile;

    formData.append('data', JSON.stringify(product));

    return formData;
}

const isProductConfirmed = (product) => product?.status === productStatuses.CONFIRMED;

const shouldDisplaySevenGreenLabelEquivalence = ({ operationSheetName, detailsPerOperation }) => {
    if (
        [
            operationSheetNames.BAR_TH_112V_A35_2,
            operationSheetNames.BAR_TH_112V_A35_2_CDP,
            operationSheetNames.BAR_TH_112V_A46_3_CDP,
            operationSheetNames.BAR_TH_112V_A46_3,
            operationSheetNames.BAR_TH_113V_A41_3_CDP,
            operationSheetNames.BAR_TH_113V_A41_3,
        ].includes(operationSheetName)
    ) {
        const compliancy = detailsPerOperation[operationSheetName]?.compliancyByInstallationTypes?.NONE?.compliant;
        const productDetailsToSpecify = detailsPerOperation[operationSheetName]?.details?.productDetailsToSpecify;
        const greenLabelSeven = productDetailsToSpecify?.find(({ label }) => label === LABEL_GREEN_FLAME_LABEL_SEVEN);

        return !greenLabelSeven?.value && compliancy;
    }
    return false;
};

export default {
    filterMainProducts,
    filterOtherProducts,
    getProductCategory,
    getProductBlockTitle,
    getValueLabel,
    getUnitLabel,
    getProductOrFormatToFormData,
    areProductPropertiesValid,
    isAMainProduct,
    getProductDetailToSpecifyValue,
    getProductExclusionDetailToSpecifyValue,
    productDetailsToSpecifyExist,
    productExclusionDetailsToSpecifyExist,
    getCertificationWording,
    getMainProduct,
    isProductConfirmed,
    productFilesExist,
    shouldDisplaySevenGreenLabelEquivalence,
};
