import { SHOW_CONFIRM_DAILOG, SHOW_WARNING_DIALOG, SHOW_SUCCESS_DIALOG, SHOW_FAILURE_DIALOG, HIDE_DIALOGS } from '../../types/ui/dialogs';

const showConfirmDialog = ({ message, onConfirm, onCancel, withChoice, confirmedButtonText, closable, renderCustomComponent, includeValidationIcon, includeWarningIcon }) => ({
    type: SHOW_CONFIRM_DAILOG,
    payload: { message, onConfirm, onCancel, confirmedButtonText, withChoice, closable, renderCustomComponent, includeValidationIcon, includeWarningIcon },
});

const showWarningDialog = ({ message, onConfirm, onCancel, withChoice, confirmedButtonText, closable, renderCustomComponent }) => ({
    type: SHOW_WARNING_DIALOG,
    payload: { message, onConfirm, onCancel, confirmedButtonText, withChoice, closable, renderCustomComponent },
});

const showSuccessDialog = ({ message, redirect = '' }) => ({
    type: SHOW_SUCCESS_DIALOG,
    payload: { message, redirect },
});

const showFailureDialog = ({ message, messages, redirect = '' }) => ({
    type: SHOW_FAILURE_DIALOG,
    payload: { message, messages, redirect },
});

const hideDialogs = () => ({
    type: HIDE_DIALOGS,
});

export default {
    showConfirmDialog,
    showSuccessDialog,
    showFailureDialog,
    showWarningDialog,
    hideDialogs,
};
