export const SEARCH_INVOICEABLE_DELEGATEES_REQUEST = 'SEARCH_INVOICEABLE_DELEGATEES_REQUEST';
export const SEARCH_INVOICEABLE_DELEGATEES_SUCCESS = 'SEARCH_INVOICEABLE_DELEGATEES_SUCCESS';
export const SEARCH_INVOICEABLE_DELEGATEES_FAILURE = 'SEARCH_INVOICEABLE_DELEGATEES_FAILURE';

export const CREATE_DELEGATEE_INVOICE_REQUEST = 'CREATE_DELEGATEE_INVOICE_REQUEST';
export const CREATE_DELEGATEE_INVOICE_SUCCESS = 'CREATE_DELEGATEE_INVOICE_SUCCESS';
export const CREATE_DELEGATEE_INVOICE_FAILURE = 'CREATE_DELEGATEE_INVOICE_FAILURE';

export const GET_DELEGATEE_INVOICE_REQUEST = 'GET_DELEGATEE_INVOICE_REQUEST';
export const GET_DELEGATEE_INVOICE_SUCCESS = 'GET_DELEGATEE_INVOICE_SUCCESS';
export const GET_DELEGATEE_INVOICE_FAILURE = 'GET_DELEGATEE_INVOICE_FAILURE';

export const GET_DELEGATEE_INVOICES_REQUEST = 'GET_DELEGATEE_INVOICES_REQUEST';
export const GET_DELEGATEE_INVOICES_SUCCESS = 'GET_DELEGATEE_INVOICES_SUCCESS';
export const GET_DELEGATEE_INVOICES_FAILURE = 'GET_DELEGATEE_INVOICES_FAILURE';

export const SEARCH_INVOICEABLE_OPERATIONS_REQUEST = 'SEARCH_INVOICEABLE_OPERATIONS_REQUEST';
export const SEARCH_INVOICEABLE_OPERATIONS_SUCCESS = 'SEARCH_INVOICEABLE_OPERATIONS_SUCCESS';
export const SEARCH_INVOICEABLE_OPERATIONS_FAILURE = 'SEARCH_INVOICEABLE_OPERATIONS_FAILURE';

export const UPLOAD_BILLING_NOTE_REQUEST = 'UPLOAD_BILLING_NOTE_REQUEST';
export const UPLOAD_BILLING_NOTE_SUCCESS = 'UPLOAD_BILLING_NOTE_SUCCESS';
export const UPLOAD_BILLING_NOTE_FAILURE = 'UPLOAD_BILLING_NOTE_FAILURE';

export const UPDATE_DELEGATEE_INVOICE_REQUEST = 'UPDATE_DELEGATEE_INVOICE_REQUEST';
export const UPDATE_DELEGATEE_INVOICE_SUCCESS = 'UPDATE_DELEGATEE_INVOICE_SUCCESS';
export const UPDATE_DELEGATEE_INVOICE_FAILURE = 'UPDATE_DELEGATEE_INVOICE_FAILURE';

export const ADD_DELEGATEE_INVOICE_OPERATIONS_REQUEST = 'ADD_DELEGATEE_INVOICE_OPERATIONS_REQUEST';
export const ADD_DELEGATEE_INVOICE_OPERATIONS_SUCCESS = 'ADD_DELEGATEE_INVOICE_OPERATIONS_SUCCESS';
export const ADD_DELEGATEE_INVOICE_OPERATIONS_FAILURE = 'ADD_DELEGATEE_INVOICE_OPERATIONS_FAILURE';

export const REMOVE_DELEGATEE_INVOICE_OPERATIONS_REQUEST = 'REMOVE_DELEGATEE_INVOICE_OPERATIONS_REQUEST';
export const REMOVE_DELEGATEE_INVOICE_OPERATIONS_SUCCESS = 'REMOVE_DELEGATEE_INVOICE_OPERATIONS_SUCCESS';
export const REMOVE_DELEGATEE_INVOICE_OPERATIONS_FAILURE = 'REMOVE_DELEGATEE_INVOICE_OPERATIONS_FAILURE';

export const DELETE_DELEGATEE_INVOICE_REQUEST = 'DELETE_DELEGATEE_INVOICE_REQUEST';
export const DELETE_DELEGATEE_INVOICE_SUCCESS = 'DELETE_DELEGATEE_INVOICE_SUCCESS';
export const DELETE_DELEGATEE_INVOICE_FAILURE = 'DELETE_DELEGATEE_INVOICE_FAILURE';

export const SAVE_DELEGATEE_INVOICE_AS_DRAFT_REQUEST = 'SAVE_DELEGATEE_INVOICE_AS_DRAFT_REQUEST';
export const SAVE_DELEGATEE_INVOICE_AS_DRAFT_SUCCESS = 'SAVE_DELEGATEE_INVOICE_AS_DRAFT_SUCCESS';
export const SAVE_DELEGATEE_INVOICE_AS_DRAFT_FAILURE = 'SAVE_DELEGATEE_INVOICE_AS_DRAFT_FAILURE';

export const FINALIZE_DELEGATEE_INVOICE_REQUEST = 'FINALIZE_DELEGATEE_INVOICE_REQUEST';
export const FINALIZE_DELEGATEE_INVOICE_SUCCESS = 'FINALIZE_DELEGATEE_INVOICE_SUCCESS';
export const FINALIZE_DELEGATEE_INVOICE_FAILURE = 'FINALIZE_DELEGATEE_INVOICE_FAILURE';

export const SEND_DELEGATEE_INVOICE_FOR_VERIFICATION_REQUEST = 'SEND_DELEGATEE_INVOICE_FOR_VERIFICATION_REQUEST';
export const SEND_DELEGATEE_INVOICE_FOR_VERIFICATION_SUCCESS = 'SEND_DELEGATEE_INVOICE_FOR_VERIFICATION_SUCCESS';
export const SEND_DELEGATEE_INVOICE_FOR_VERIFICATION_FAILURE = 'SEND_DELEGATEE_INVOICE_FOR_VERIFICATION_FAILURE';

export const UPDATE_DELEGATEE_INVOICE_OPERATION = 'UPDATE_DELEGATEE_INVOICE_OPERATION';
