export const LABEL_BUTTON_CLOSE_MODAL = 'Close modal';

export const LABEL_BUTTON_VIEW_EXPANDABLE_ITEM = 'View expandable item';
export const LABEL_BUTTON_EDIT_EXPANDABLE_ITEM = 'Edit expandable item';
export const LABEL_BUTTON_DELETE_EXPANDABLE_ITEM = 'Delete expandable item';

export const LABEL_BUTTON_EDIT_CONTACT = 'Edit Contact';
export const LABEL_BUTTON_CONFIRM = 'Confirmer';
export const LABEL_BUTTON_OK = 'OK';
export const LABEL_BUTTON_REJECT = 'Non';
export const LABEL_BUTTON_ACCEPT = 'Oui';
export const LABEL_BUTTON_CREATE = 'Créer';
export const LABEL_BUTTON_PREVIEW = 'Aperçu';
export const LABEL_BUTTON_PRINT = 'Imprimer';
export const LABEL_BUTTON_DELETE = 'Supprimer';
export const LABEL_BUTTON_CREATE_NEW_QUOTATION_WHEN_EXPIRED = 'Créer un nouveau devis avec les mêmes éléments';

export const TAB_CONSUMER_MEDIATOR = 'Médiateur';
export const LABEL_BUTTON_SAVE = 'Enregistrer';
export const LABEL_BUTTON_SEND = 'Envoyer';
export const LABEL_BUILDING_SECTOR = 'Secteur du bâtiment';
export const LABEL_HOUSING_TYPE = 'Type de logement';
export const LABEL_ACTIVITY_AREA = "Secteur d'activité";
export const LABEL_CADASTRAL_PARCEL_NUMBER = 'Numéro de parcelle cadastrale';

export const LABEL_INPUT_GENDER = 'Civilité';
export const LABEL_CHECKBOX_GENDER_MALE = 'Monsieur';
export const LABEL_CHECKBOX_GENDER_FEMALE = 'Madame';
export const LABEL_INPUT_LAST_NAME = 'Nom';
export const LABEL_INPUT_FIRST_NAME = 'Prénom';
export const LABEL_SELECT_FONCTION = 'FONCTION';
export const LABEL_INPUT_EMAIL = 'Adresse email';
export const LABEL_INPUT_PHONE = 'Téléphone fixe';
export const LABEL_INPUT_MOBILE = 'Téléphone mobile';
export const LABEL_INPUT_PHONE_NUMBER = 'Numéro de téléphone';
export const LABEL_INPUT_DECLARER = 'Choisissez le client parmi les déclarants :';

export const LABEL_INPUT_STREET_NUMBER = 'Numéro de voie';
export const LABEL_INPUT_CADASTRAL_PARCEL_NUMBER = 'Numéro de parcelle cadastrale (obligatoire en l’absence de numéro de voie)';
export const LABEL_INPUT_STREET_NAME = 'Voie';
export const LABEL_INPUT_CITY = 'Ville';
export const LABEL_INPUT_POSTAL_CODE = 'Code postal';
export const LABEL_INPUT_COUNTRY = 'Pays';

export const LABEL_INPUT_TAX_NOTICE_NUMBER = 'Numéro fiscal (13 chiffres sans espace)';
export const LABEL_INPUT_TAX_NOTICE_REFERENCE = "Référence de l'avis d'imposition (13 caractères alphanumériques sans espace)";
export const LABEL_INPUT_TAX_NOTICE_NUMBER_SHORT = 'Numéro fiscal';
export const LABEL_INPUT_TAX_NOTICE_REFERENCE_SHORT = "Référence de l'avis d'imposition";
export const LABEL_BUTTION_VERIFY = 'Vérifier';

export const LABEL_INPUT_ADDRESS = 'Adresse';
export const LABEL_INPUT_HOUSING_NUMBER = 'Numéro du logement';

export const LABEL_CHECKBOX_WITHOUT_FISCAL_INFORMATION = 'Sans références fiscales';
export const LABEL_CHECKBOX_OCCUPANT_IS_BENEFICIARY = 'Cet occupant est le bénéficiare';

export const LABEL_BUTTON_REGENERATE = 'Regénérer';

export const LABEL_BUTTON_EXPORT_CSV = 'Export CSV';

export const LABEL_BUTTON_MENU = 'Menu';
export const LABEL_MENU_ITEM_DELETE = 'Supprimer';

export const LABEL_AUTOCOMPLETE_CREATE_SUBCONTRACTOR = 'Merci de renseigner le numéro siren ou la raison sociale de votre nouveau sous-traitant';

export const LABEL_COLLABORATOR_EMAIL = 'Adresse email du collaborateur';

export const LABEL_PRINT = 'Imprimer';
export const LABEL_SEND = 'Envoyer';
export const LABEL_RESEND = 'Relancer';
export const LABEL_CREATE = 'Créer';

export const LABEL_INPUT_DESCRIPTION = 'Description';

export const LABEL_LINK_UPLOADED_FILE = 'Lien vers le fichier';
export const LABEL_BUTTON_DELETE_UPLOADED_FILE = 'Delete uploaded file';

export const MODAL_TITILE_IMPORTANT_INFORMATION = 'Information importante';

export const LABEL_INPUT_HEATED_AREA = 'Surface chauffée (m²)';
export const LABEL_INPUT_EXCLUSIVE_HEATED_AREA = 'Surface exclusivement chauffée par la pompe à chaleur à installer (m²)';

export const LABEL_COMMENT = 'Commentaire';
export const LABEL_INPUT_COMMENT_OPTIONAL = 'Commentaire (optionnel)';
