import { isNonEmptyObject } from '../../utils';
import { UPDATE_ACCESS_RIGHTS_SUCCESS } from '../types/auth';
import {
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_FAILURE,
    PATCH_ORGANIZATION_SUCCESS,
    CREATE_ORGANIZATION_SUCCESS,
    UPDATE_ORGANIZATION_ONBOARDING_SUCCESS,
    GET_ORGANIZATION_REQUEST,
    DELETE_INVITATION_SUCCESS,
    REFRESH_ORGANIZATION_INFORMATION_SUCCESS,
    UPDATE_ORGANIZATION_STAMP_SUCCESS,
    DELETE_ORGANIZATION_STAMP_SUCCESS,
} from '../types/organization.types';

const initState = () => ({
    organization: null,
    loading: true,
});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_ORGANIZATION_REQUEST:
            return {
                ...state,
                organization: action.payload,
                loading: true,
            };
        case GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: action.payload,
                loading: false,
            };
        case GET_ORGANIZATION_FAILURE:
            return {
                ...state,
                organization: null,
                loading: false,
            };
        case CREATE_ORGANIZATION_SUCCESS: {
            return {
                ...state,
                organization: action.payload,
            };
        }
        case PATCH_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: action.payload,
            };
        case UPDATE_ORGANIZATION_ONBOARDING_SUCCESS:
            return {
                ...state,
                organization: action.payload,
            };
        case UPDATE_ORGANIZATION_STAMP_SUCCESS:
            return {
                ...state,
                organization: action.payload,
            };
        case DELETE_ORGANIZATION_STAMP_SUCCESS:
            return {
                ...state,
                organization: action.payload,
            };
        case DELETE_INVITATION_SUCCESS:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    organizationInvitations: state.organization.organizationInvitations.filter((invitation) => invitation.uuid !== action.payload.invitation.uuid),
                },
            };

        case REFRESH_ORGANIZATION_INFORMATION_SUCCESS:
            return {
                ...state,
                organization: {
                    ...state.organization,
                    ...action.payload,
                },
            };

        case UPDATE_ACCESS_RIGHTS_SUCCESS: {
            return {
                ...state,
                organization: {
                    ...state.organization,
                    organizationInvitations: state.organization.organizationInvitations.map((invitation) => {
                        const account = action.payload.accounts.find(({ id }) => id === invitation?.account?.id);
                        if (isNonEmptyObject(account)) {
                            return {
                                ...invitation,
                                account,
                            };
                        }

                        return invitation;
                    }),
                },
            };
        }

        default:
            return state;
    }
};
