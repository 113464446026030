import delegateeInvoiceHelper from '../../utils/delegatee-invoice.helper';
import {
    SEARCH_INVOICEABLE_DELEGATEES_REQUEST,
    SEARCH_INVOICEABLE_DELEGATEES_SUCCESS,
    CREATE_DELEGATEE_INVOICE_SUCCESS,
    GET_DELEGATEE_INVOICE_SUCCESS,
    GET_DELEGATEE_INVOICES_SUCCESS,
    SEARCH_INVOICEABLE_OPERATIONS_SUCCESS,
    UPLOAD_BILLING_NOTE_SUCCESS,
    UPDATE_DELEGATEE_INVOICE_SUCCESS,
    ADD_DELEGATEE_INVOICE_OPERATIONS_SUCCESS,
    REMOVE_DELEGATEE_INVOICE_OPERATIONS_SUCCESS,
    DELETE_DELEGATEE_INVOICE_SUCCESS,
    SAVE_DELEGATEE_INVOICE_AS_DRAFT_SUCCESS,
    FINALIZE_DELEGATEE_INVOICE_SUCCESS,
    SEND_DELEGATEE_INVOICE_FOR_VERIFICATION_SUCCESS,
    UPDATE_DELEGATEE_INVOICE_OPERATION,
} from '../types/delegatee-invoices.types';

function initState() {
    return {
        invoiceableDelegateesLoading: false,
        invoiceableDelegatees: [],
        invoiceableOperations: [],
        delegateeInvoices: [],
        delegateeInvoice: {},
    };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case SEARCH_INVOICEABLE_DELEGATEES_REQUEST:
            return {
                ...state,
                invoiceableDelegatees: [],
            };

        case SEARCH_INVOICEABLE_DELEGATEES_SUCCESS:
            return {
                ...state,
                invoiceableDelegatees: payload.invoiceableDelegatees,
            };

        case GET_DELEGATEE_INVOICES_SUCCESS:
            return {
                ...state,
                delegateeInvoices: payload.delegateeInvoices,
            };

        case SEARCH_INVOICEABLE_OPERATIONS_SUCCESS:
            return {
                ...state,
                invoiceableOperations: payload.invoiceableOperations,
            };

        case CREATE_DELEGATEE_INVOICE_SUCCESS:
        case GET_DELEGATEE_INVOICE_SUCCESS:
        case UPLOAD_BILLING_NOTE_SUCCESS:
        case UPDATE_DELEGATEE_INVOICE_SUCCESS:
        case ADD_DELEGATEE_INVOICE_OPERATIONS_SUCCESS:
        case REMOVE_DELEGATEE_INVOICE_OPERATIONS_SUCCESS:
        case SAVE_DELEGATEE_INVOICE_AS_DRAFT_SUCCESS:
        case FINALIZE_DELEGATEE_INVOICE_SUCCESS:
        case SEND_DELEGATEE_INVOICE_FOR_VERIFICATION_SUCCESS:
            return {
                ...state,
                delegateeInvoice: payload.delegateeInvoice,
            };

        case DELETE_DELEGATEE_INVOICE_SUCCESS:
            return {
                ...state,
                delegateeInvoices: state.delegateeInvoices.length > 0 ? state.delegateeInvoices.filter(({ uuid }) => uuid !== payload.delegateeInvoice.uuid) : [],
                delegateeInvoice: {},
            };

        case UPDATE_DELEGATEE_INVOICE_OPERATION:
            return {
                ...state,
                delegateeInvoice: {
                    ...state.delegateeInvoice,
                    operations: delegateeInvoiceHelper.updateDelegateeInvoiceOperation(state.delegateeInvoice, payload),
                },
            };

        default:
            return state;
    }
}
