import {
    SIGN_IN_SUCCESS,
    SIGN_UP_SUCCESS,
    GET_ACCOUNT_SUCCESS,
    LOGOUT,
    CONFIRM_VALIDATION_TOKEN_SUCCESS,
    CONFIRM_VALIDATION_TOKEN_FAILURE,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ONBOARDING_DIALOGS_ACTIONS_SUCCESS,
} from '../types/auth';
import storage from '../../utils/storage';

const initState = () => ({
    token: storage.getToken(),
    account: {
        isVerified: true,
        hasDoneFirstLogin: true,
    },
});

export default (state = initState(), action) => {
    switch (action.type) {
        case SIGN_UP_SUCCESS: {
            return {
                token: action.payload.token,
                account: action.payload,
            };
        }
        case SIGN_IN_SUCCESS: {
            return {
                token: action.payload.token,
                account: action.payload,
            };
        }
        case GET_ACCOUNT_SUCCESS: {
            return {
                token: state.token,
                account: action.payload.lastActionType === SIGN_UP_SUCCESS ? state.account : action.payload.account,
            };
        }
        case CONFIRM_VALIDATION_TOKEN_SUCCESS: {
            return {
                token: state.token,
                account: state.account,
            };
        }
        case CONFIRM_VALIDATION_TOKEN_FAILURE: {
            return {
                token: state.token,
                account: state.account,
                errorMessage: action.payload.errorMessage,
            };
        }
        case LOGOUT: {
            return {
                token: '',
                account: {},
            };
        }
        case UPDATE_ACCOUNT_SUCCESS: {
            return {
                token: state.token,
                account: action.payload.account,
            };
        }

        case UPDATE_ONBOARDING_DIALOGS_ACTIONS_SUCCESS:
            return {
                ...state,
                account: action.payload.account,
            };

        default:
            return state;
    }
};
