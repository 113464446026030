export const pagesType = Object.freeze({
    DASHBOARD: 'DASHBOARD',
    ORGANIZATION: 'ORGANIZATION',
    SUB_CONTRACTORS: 'SUB_CONTRACTORS',
    CONTRACTS: 'CONTRACTS',
    PRODUCTS: 'PRODUCTS',
    CUSTOMERS: 'CUSTOMERS',
    QUOTATIONS: 'QUOTATIONS',
    INVOICES: 'INVOICES',
    DELEGATEE_INVOICES: 'DELEGATEE_INVOICES',
    DOSSIERS_CEE: 'DOSSIERS_CEE',
    DOCUMENTS: 'DOCUMENTS',
    PLANNING: 'PLANNING',
    DOSSIERS_MAPRIMERENOV: 'DOSSIERS_MAPRIMERENOV',
});

export const roles = Object.freeze({
    ADMIN: 'ADMIN',
    TECHNICIAN: 'TECHNICIAN',
    MA_PRIME_RENOV_REPRESENTATIVE_ACCESS: 'MA_PRIME_RENOV_REPRESENTATIVE_ACCESS',
    OPERATOR: 'OPERATOR',
});

export const sidebarStates = {
    DEFAULT: 'default',
    EXPANDED: 'sidebar-expanded',
    SHRINKED: 'sidebar-shrinked',
};

export const patchOperations = {
    ADD: 'add',
    UPDATE: 'update',
    SAFEDELETE: 'safe-delete',
    REMOVE: 'remove',
    DELETE: 'delete',
};

export const patchOrganizationPaths = {
    ORGANIZATION: '/organization',
    ADDRESSES: '/organization-addresses',
    CONTACTS: '/organization-contacts',
    BILLING: '/organization-billing',
    RGE_CERTIFICATES: '/organization-rge-certificates',
    INSURANCES: '/organization-insurances',
    KBIS: '/organization-kbis-extracts',
    CONSUMER_MEDIATOR: '/consumer-mediator',
};

export const patchRenovationAddressPaths = {
    HOUSEHOLD_INCOME_BONIFICATION: '/household-income-bonification',
    SOCIAL_LANDLORD_BONIFICATION: '/social-landlord-bonification',
    QPV_BONIFICATION: '/qpv-bonification',
};

export const patchCustomerPMPaths = {
    CUSTOMER_PM: '/',
    ADDRESSES: '/addresses',
    CONTACTS: '/contacts',
};

export const patchSubContractorPaths = {
    SUB_CONTRACTOR: '/sub-contractors',
    ADDRESSES: '/addresses',
    CONTACTS: '/contacts',
    RGE_CERTIFICATES: '/rge-certificates',
};

export const patchContractPaths = {
    CONTRACT: '/contracts',
    CONTRACT_AMENDMENT: '/contract-amendments',
};

export const patchQuotationPaths = {
    CUSTOMER: '/customer',
    ADDRESS: '/address',
    CONTRACT: '/contract',
};

export const organizationInsuranceWarrantyAreas = Object.freeze({
    WHOLE_FRANCE: 'WHOLE_FRANCE',
    METROPOLITAN_FRANCE: 'METROPOLITAN_FRANCE',
    DOM_TOM: 'DOM_TOM',
});

export const organizationInsuranceWarrantyAreasTranslations = Object.freeze({
    WHOLE_FRANCE: 'France entière',
    METROPOLITAN_FRANCE: 'France métropolitaine',
    DOM_TOM: 'DOM-TOM',
});

export const genders = {
    MALE: 'Monsieur',
    FEMALE: 'Madame',
};

export const addressModalTitles = Object.freeze({
    CREATE: 'Nouvelle adresse',
    EDIT: 'Modifier une adresse',
});

export const contactModalTitles = Object.freeze({
    CREATE: 'Ajouter un contact',
    EDIT: 'Modifier un contact',
});

export const renovationAddressHousingTypesTranslations = {
    INDIVIDUAL_HOUSE: 'Maison individuelle',
    APARTMENT: 'Appartement(s)',
};

export const renovationAddressHousingTypes = {
    INDIVIDUAL_HOUSE: 'INDIVIDUAL_HOUSE',
    APARTMENT: 'APARTMENT',
};

export const renovationAddressTypes = {
    HOME: 'Domicile',
    WORK: 'Travaux',
};

export const customerPMActivitiesTranslations = {
    SOCIAL_LANDLORD: 'Bailleur social',
    COOWNED_SYNDICATE: 'Syndic de copropriété',
    OTHER: 'Autre',
};

export const customerPMActivities = {
    SOCIAL_LANDLORD: 'SOCIAL_LANDLORD',
    COOWNED_SYNDICATE: 'COOWNED_SYNDICATE',
    OTHER: 'OTHER',
};

export const renovationAddressBuildingSectorsTranslations = {
    RESIDENTIAL: 'Résidentiel',
    TERTIARY: 'Tertiaire',
};

export const renovationAddressBuildingSectors = {
    RESIDENTIAL: 'RESIDENTIAL',
    TERTIARY: 'TERTIARY',
};

export const renovationAddressActivityAreasTranslations = {
    OFFICES: 'Bureaux',
    EDUCATION: 'Education',
    COMMERCES: 'Commerces',
    HOTELS_CATERING: 'Hôtellerie-Restauration',
    HEALTH: 'Santé',
    OTHERS: 'Autres secteurs',
};

export const renovationAddressActivityAreas = {
    OFFICES: 'OFFICES',
    EDUCATION: 'EDUCATION',
    COMMERCES: 'COMMERCES',
    HOTELS_CATERING: 'HOTELS_CATERING',
    HEALTH: 'HEALTH',
    OTHERS: 'OTHERS',
};

export const quotationStatuses = Object.freeze({
    DRAFT: 'DRAFT',
    COMPLETED: 'COMPLETED',
    SENT: 'SENT',
    SIGNED: 'SIGNED',
    SIGNED_AND_FINALIZED_RELATED_INVOICE: 'SIGNED_AND_FINALIZED_RELATED_INVOICE',
    EXPIRED: 'EXPIRED',
    REFUSED: 'REFUSED',
});

export const quotationStatusesLabels = Object.freeze({
    DRAFT: 'Brouillon',
    COMPLETED: 'Complété',
    SENT: 'Envoyé',
    SIGNED: 'Accepté',
    SIGNED_AND_FINALIZED_RELATED_INVOICE: 'Facturé',
    EXPIRED: 'Expiré',
    REFUSED: 'Refusé par le client',
});

export const quotationStatusesActionButtonLabels = Object.freeze({
    DRAFT: 'Compléter',
    COMPLETED: 'Modifier',
    SENT: 'Relancer',
    SIGNED: 'Créer la facture',
    SIGNED_AND_FINALIZED_RELATED_INVOICE: 'Consulter',
    EXPIRED: 'Consulter',
    REFUSED: 'Marquer comme "Refusé par le client"',
    SENT_TO_CUSTOMER: 'Marquer comme "Envoyé au client"',
    SEND: 'Envoyer',
    DUPLICATE: 'Dupliquer',
});

export const customerTypes = Object.freeze({
    CUSTOMER_PP: 'CUSTOMER_PP',
    CUSTOMER_PM: 'CUSTOMER_PM',
});

export const customerPMPageActions = Object.freeze({
    CREATE: 'creer',
});

export const customerPageTypes = Object.freeze({
    CUSTOMER_PP: 'particulier',
    CUSTOMER_PM: 'morale',
});

export const administrativeContactRoles = Object.freeze({
    Maire: 'Maire',
    ResponsableHSE: 'Responsable HSE',
    Directeur: 'Directeur',
    Dirigeant: 'Dirigeant',
    Administratif: 'Administratif',
    Financier: 'Financier',
    Commercial: 'Commercial',
    Technicien: 'Technicien',
    SAV: 'SAV',
});

export const inputTypes = Object.freeze({
    DATE: 'date',
});

export const quotationTechnicianTypes = Object.freeze({
    ORGANIZATION: 'ORGANIZATION',
    SUB_CONTRACTOR: 'SUB_CONTRACTOR',
});

export const operationInstallationTypes = Object.freeze({
    COMBLE_PERDU: 'COMBLE_PERDU',
    RAMPANT_DE_TOITURE: 'RAMPANT_DE_TOITURE',
    FENETRES_DE_TOITURE: 'FENETRES_DE_TOITURE',
    TERRASSE_DE_TOITURE: 'TERRASSE_DE_TOITURE',
    AUTRES_FENETRES: 'AUTRES_FENETRES',
    PORTES_FENETRES: 'PORTES_FENETRES',
    COLLECTIVE_INSTALLATION: 'COLLECTIVE_INSTALLATION',
    INDIVIDUAL_INSTALLATION: 'INDIVIDUAL_INSTALLATION',
    AIR_EAU: 'AIR_EAU',
    EAU_EAU: 'EAU_EAU',
    TYPE_A: 'TYPE_A',
    TYPE_B: 'TYPE_B',
    NONE: 'NONE',
    MUR_INTERIEUR: 'MUR_INTERIEUR',
    MUR_EXTERIEUR: 'MUR_EXTERIEUR',
    RESEAU_HYDRAULIQUE_DE_CHAUFFAGE: 'RESEAU_HYDROLIQUE_DE_CHAUFFAGE',
    RESEAU_HYDRAULIQUE_EAU_CHAUDE_SANITAIRE: 'RESEAU_HYDRAULIQUE_EAU_CHAUDE_SANITAIRE',
    AIR_EXTRAIT: 'AIR_EXTRAIT',
    AUTRE_TYPE_INSTALLATION: 'AUTRE_TYPE_INSTALLATION',
    AIR_AMBIANT: 'AIR_AMBIANT',
    SPLIT: 'SPLIT',
    INDIVIDUAL_INSTALLATION_SELF_ADJUSTING: 'INDIVIDUAL_INSTALLATION_SELF_ADJUSTING',
    INDIVIDUAL_INSTALLATION_MODULATED: 'INDIVIDUAL_INSTALLATION_MODULATED',
    COLLECTIVE_INSTALLATION_SELF_ADJUSTING: 'COLLECTIVE_INSTALLATION_SELF_ADJUSTING',
});

export const operationInstallationTypesTranslations = Object.freeze({
    COMBLE_PERDU: 'Comble perdu',
    RAMPANT_DE_TOITURE: 'Rampant de toiture',
    MUR_INTERIEUR: "Par l'intérieur",
    MUR_EXTERIEUR: "Par l'extérieur",
    FENETRES_DE_TOITURE: 'Fenêtre(s) de toiture',
    AUTRES_FENETRES: 'Autre(s) fenêtre(s)',
    PORTES_FENETRES: 'Porte(s)-fenêtre(s)',
    AIR_EAU: 'Air/eau',
    EAU_EAU: 'Eau/eau',
    COLLECTIVE_INSTALLATION: 'Installation collective',
    INDIVIDUAL_INSTALLATION: 'Installation individuelle',
    TERRASSE_DE_TOITURE: 'Toiture terrasse',
    RESEAU_HYDRAULIQUE_DE_CHAUFFAGE: 'Réseau hydraulique de chauffage',
    RESEAU_HYDRAULIQUE_EAU_CHAUDE_SANITAIRE: "Réseau hydraulique d'eau chaude sanitaire",
    AIR_EXTRAIT: 'Air extrait',
    AUTRE_TYPE_INSTALLATION: 'Autre(s) installation(s)',
    AIR_AMBIANT: 'Air ambiant',
    SPLIT: 'Split',
    INDIVIDUAL_INSTALLATION_SELF_ADJUSTING: 'Installation individuelle autoréglable',
    INDIVIDUAL_INSTALLATION_MODULATED: 'Installation individuelle modulée',
    COLLECTIVE_INSTALLATION_SELF_ADJUSTING: 'Installation collective autoréglable',
});

export const operationInstallationTypesLabels = Object.freeze({
    FENETRES_DE_TOITURE: 'Fenêtres de toiture',
    AUTRES_FENETRES: 'Autres fenêtres',
    PORTES_FENETRES: 'Portes-fenêtres',
});

export const operationHeatingTypes = Object.freeze({
    COMBUSTIBLE: 'COMBUSTIBLE',
    ELECTRICITE: 'ELECTRICITE',
    UNSPECIFIED: 'UNSPECIFIED',
});

export const operationHeatingTypesLabels = Object.freeze({
    COMBUSTIBLE: 'Combustible',
    ELECTRICITE: 'Électricité',
    'Non renseignée': 'Non renseignée',
});

export const productCategories = Object.freeze({
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: 'Appareil indépendant de chauffage au bois',
    CALORIFUGEAGE: 'Calorifugeage',
    CHAUDIERE_INDIVIDUELLE: 'Chaudière individuelle',
    CHAUFFE_EAU_SOLAIRE_INDIVIDUEL: 'Chauffe-eau solaire individuel',
    CHAUFFE_EAU_THERMODYNAMIQUE: 'Chauffe-Eau Thermodynamique',
    FENETRE: 'Fenêtres, autres fenêtres ou portes-fenêtres',
    ISOLANT: 'Isolant',
    POMPE_CHALEUR: 'Pompe à chaleur',
    TOITURE: 'Toiture ou éléments de toiture',
    VENTILATION: 'Ventilation',
    SYSTEME_SOLAIRE_COMBINE: 'Système solaire combiné',
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'Chaudiere biomasse individuelle',
});

export const productCategoriesValues = Object.freeze({
    ISOLANT: 'ISOLANT',
    POMPE_CHALEUR: 'POMPE_CHALEUR',
    CHAUFFE_EAU_SOLAIRE_INDIVIDUEL: 'CHAUFFE_EAU_SOLAIRE_INDIVIDUEL',
    TOITURE: 'TOITURE',
    FENETRE: 'FENETRE',
    CHAUDIERE_INDIVIDUELLE: 'CHAUDIERE_INDIVIDUELLE',
    VENTILATION: 'VENTILATION',
    CALORIFUGEAGE: 'CALORIFUGEAGE',
    CHAUFFE_EAU_THERMODYNAMIQUE: 'CHAUFFE_EAU_THERMODYNAMIQUE',
    SYSTEME_SOLAIRE_COMBINE: 'SYSTEME_SOLAIRE_COMBINE',
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: 'APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS',
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'CHAUDIERE_BIOMASSE_INDIVIDUELLE',
});

export const ventilationTypes = Object.freeze({
    TYPE_A: 'TYPE_A',
    TYPE_B: 'TYPE_B',
});

export const ventilationTypesLabels = Object.freeze({
    TYPE_A: 'Type A',
    TYPE_B: 'Type B',
});

export const productVatRates = Object.freeze({
    METROPOLIS_NORMAL_VAT_RATE: 0.2,
    RENOVATION_SPECIFIC_VAT_RATE: 0.1,
    ZNI_NORMAL_VAT_RATE: 0.085,
    METROPOLIS_REDUCED_VAT_RATE: 0.055,
    ZNI_REDUCED_VAT_RATE: 0.021,
    ZERO_VAT_RATE: 0,
});

export const contractPricesTypesEnum = Object.freeze({
    PRECARITY: 'PRECARITY',
    CLASSIC: 'CLASSIC',
    PRECARITY_CDP: 'PRECARITY CDP',
    CLASSIC_CDP: 'CLASSIC CDP',
});

export const contractPriceTypes = Object.freeze({
    CLASSIC: 'CLASSIC',
    PRECARITY: 'PRECARITY',
    PRECARITY_CDP: 'PRECARITY CDP',
    CLASSIC_CDP: 'CLASSIC CDP',
});

export const invoiceStatuses = Object.freeze({
    DRAFT: 'DRAFT',
    FINALIZED: 'FINALIZED',
    SENT: 'SENT',
    PAID: 'PAID',
    CANCELLED: 'CANCELLED',
    RECTIFIED: 'RECTIFIED',
    SENT_FOR_BACK_OFFICE_VERIFICATION: 'SENT_FOR_BACK_OFFICE_VERIFICATION',
});

export const invoiceStatusesLabels = Object.freeze({
    DRAFT: 'Brouillon',
    FINALIZED: 'Finalisée',
    SENT: 'Envoyée',
    PAID: 'Payée',
    CANCELLED: 'Annulée',
    RECTIFIED: 'Rectifiée',
    SENT_FOR_BACK_OFFICE_VERIFICATION: 'Envoyée',
});

export const invoiceStatusesActionButtonLabels = Object.freeze({
    DRAFT: 'Supprimer',
    FINALIZED: 'Envoyer',
    SENT: 'Confirmer paiement',
    CANCELLED: 'Consulter',
    PAID: 'Annuler',
    RECTIFIED: 'Consulter',
});

export const dossierCEEStatuses = Object.freeze({
    PROCESSING: 'PROCESSING',
    COMPLETED: 'COMPLETED',
    SENT: 'SENT',
    SENT_FOR_BACK_OFFICE_VERIFICATION: 'SENT_FOR_BACK_OFFICE_VERIFICATION',
    CANCELLED: 'CANCELLED',
    CANCELLED_FROM_BACK_OFFICE: 'CANCELLED_FROM_BACK_OFFICE',
});

export const dossierCEESendingTypes = Object.freeze({
    EMAIL: 'EMAIL',
    MAIL: 'MAIL',
});

export const housingSituations = Object.freeze({
    PRECARITY: 'PRECARITY',
    GREAT_PRECARITY: 'GREAT_PRECARITY',
    CLASSIC: 'CLASSIC',
});

export const housingSituationsTranslation = Object.freeze({
    PRECARITY: 'Modeste',
    GREAT_PRECARITY: 'Précarité énergétique',
    CLASSIC: 'Classique',
});

export const swornStatementsTypes = Object.freeze({
    CADRE_A: 'CADRE_A',
    CADRE_B: 'CADRE_B',
    CADRE_C: 'CADRE_C',
    CADRE_R1: 'CADRE_R1',
    CADRE_R2: 'CADRE_R2',
    CADRE_BS: 'CADRE_BS',
    CADRE_CD: 'CADRE_CD',
    CADRE_QPV: 'CADRE_QPV',
});

export const uploadDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    SWORN_STATEMENT: 'SWORN_STATEMENT',
    CADASTRAL_PLOT: 'CADASTRAL_PLOT',
    POST_RENOVATION_REPORT: 'POST_RENOVATION_REPORT',
    SITE_PHOTO_BEFORE_RENOVATION: 'SITE_PHOTO_BEFORE_RENOVATION',
    SITE_PHOTO_AFTER_RENOVATION: 'SITE_PHOTO_AFTER_RENOVATION',
    SIZING_NOTE: 'SIZING_NOTE',
    SOLAR_FACTOR_CALCULATION_NOTE: 'SOLAR_FACTOR_CALCULATION_NOTE',
    DOMICILIATION_CERTIFICATE: 'DOMICILIATION_CERTIFICATE',
    MAPRIME_RENOV_CERTIFICATE: 'MAPRIME_RENOV_CERTIFICATE',
    PROOF_OF_OWNERSHIP: 'PROOF_OF_OWNERSHIP',
    JOINT_OWNER_SHIP_CERTIFICATE: 'JOINT_OWNER_SHIP_CERTIFICATE',
    OWNER_LESSOR_COMMITMENT_CERTIFICATE: 'OWNER_LESSOR_COMMITMENT_CERTIFICATE',
    CLAIM_ASSIGNMENT: 'CLAIM_ASSIGNMENT',
    MOVING_CERTIFICATE: 'MOVING_CERTIFICATE',
    OTHER: 'OTHER',
});

export const uploadDocumentTypesTranslations = Object.freeze({
    QUOTATION: 'Devis',
    SWORN_STATEMENT: "Attestation sur l'honneur",
    POST_RENOVATION_REPORT: 'Rapport post-travaux',
    CADASTRAL_PLOT: 'Parcelle cadastrale',
    SITE_PHOTO: 'Photo du site',
    SITE_PHOTO_BEFORE_RENOVATION: 'Photo du site avant travaux',
    SITE_PHOTO_AFTER_RENOVATION: 'Photo du site après travaux',
    SIZING_NOTE: 'Note de dimensionnement',
    SOLAR_FACTOR_CALCULATION_NOTE: 'Note de calcul du facteur solaire',
    DOMICILIATION_CERTIFICATE: 'Attestation de domiciliation',
    MAPRIME_RENOV_CERTIFICATE: "Mandat MaPrimeRénov' signé",
    PROOF_OF_OWNERSHIP: 'Justificatif de propriété',
    JOINT_OWNER_SHIP_CERTIFICATE: "Attestation d'indivision",
    OWNER_LESSOR_COMMITMENT_CERTIFICATE: "Attestation d'engagement propriétaire bailleur",
    CLAIM_ASSIGNMENT: 'Cession de créance signée',
    MOVING_CERTIFICATE: 'Attestation de déménagement',
    OTHER: 'Autre',
});

export const otherDocumentTypes = Object.freeze({
    SOLAR_CERTIFICATION: 'SOLAR_CERTIFICATION',
});

export const uploadDocumentFileTypes = Object.freeze({
    PDF: 'PDF',
    IMAGE: 'IMAGE',
    URL: 'URL',
});

export const productCreationOriginTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
});

export const paymentTypesEnum = Object.freeze({
    BANK_TRANSFER: 'BANK_TRANSFER',
    BANK_CHECK: 'BANK_CHECK',
    CASH: 'CASH',
});

export const paymentTypesTranslations = Object.freeze({
    CASH: 'Espèces',
    BANK_CHECK: 'Chèque',
    BANK_TRANSFER: 'Virement bancaire',
});

export const productTypes = Object.freeze({
    MAIN_PRODUCT: 'MAIN_PRODUCT',
    INDUCED_WORK_PRODUCT: 'INDUCED_WORK_PRODUCT',
    OTHER_PRODUCT: 'OTHER_PRODUCT',
});

export const productTypeTranslations = {
    MAIN_PRODUCT: 'Produit de rénovation énergétique',
    OTHER_PRODUCT: 'Autre produit ou service',
};

export const unitTypes = Object.freeze({
    SQUARE_METER: 'SQUARE_METER',
    METER: 'METER',
    LITER: 'LITER',
    UNIT: 'UNIT',
    CUBIC_METER: 'CUBIC_METER',
});

export const productUnitsLabelEnum = Object.freeze({
    METER: 'm',
    SQUARE_METER: 'm²',
    CUBIC_METER: 'm³',
    LITER: 'litres',
    UNIT: 'unités',
});

export const productUnitsLabelEnumSingular = Object.freeze({
    METER: 'm',
    SQUARE_METER: 'm²',
    CUBIC_METER: 'm³',
    LITER: 'litre',
    UNIT: 'unité',
});

export const heatPumpTypesLabel = Object.freeze({
    'medium-high-temperature-heat-pump': 'Pompe à chaleur moyenne ou haute température',
    'low-temperature-heat-pump': 'Pompe à chaleur basse température',
});

export const heatingPumpFunctioningTypes = Object.freeze({
    MEDIUM_AND_HIGH_TEMPERATURE: 'medium-high-temperature-heat-pump',
    LOW_TEMPERATURE: 'low-temperature-heat-pump',
});

export const ventilationBoxTypes = Object.freeze({
    STANDARD: 'Ventilation mécanique avec caisson standard',
    LOW_CONSUMPTION: 'Ventilation mécanique avec caisson basse consommation',
    LOW_PRESSURE: 'Ventilation mécanique avec caisson basse pression',
});

export const uploadedDocumentStatuses = Object.freeze({
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
    CANCELLED_FROM_BACK_OFFICE: 'CANCELLED_FROM_BACK_OFFICE',
});

export const uploadedDocumentStatusesLabels = Object.freeze({
    PENDING: 'Vérification',
    CONFIRMED: 'Validé',
    REJECTED: 'Rejeté',
    EXPIRED: 'Expiré',
    CANCELLED_FROM_BACK_OFFICE: 'Annulé',
});

export const sendableDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
    DELEGATEE_INVOICE: 'DELEGATEE_INVOICE',
    EMAIL: 'EMAIL',
});

export const dossierCEESidebarDocumentFileTypes = Object.freeze({
    PDF: 'PDF',
    PDFS: 'PDFS',
    IMAGE: 'IMAGE',
    IMAGES: 'IMAGES',
    EMPTY: 'EMPTY',
});

export const dossierCEESidebarDocumentBlockTypes = Object.freeze({
    TAX_NOTICES: 'TAX_NOTICES',
});

export const invitationStatuses = Object.freeze({
    SENT: 'SENT',
    ACCEPTED: 'ACCEPTED',
    CONFIRMED: 'CONFIRMED',
});

export const invitationStatusesTranslation = Object.freeze({
    SENT: 'Envoyée',
    ACCEPTED: 'Acceptée',
    CONFIRMED: 'Confirmée',
});

export const maPrimeRenovTypes = Object.freeze({
    BLUE: 'BLUE',
    YELLOW: 'YELLOW',
    PURPLE: 'PURPLE',
    PINK: 'PINK',
    NONE: 'NONE',
});

export const maPrimeRenovLabels = Object.freeze({
    BLUE: 'Bleu',
    YELLOW: 'Jaune',
    PURPLE: 'Violet',
    PINK: 'Rose',
});

export const applicationPages = Object.freeze({
    SUB_CONTRACTOR: 'SUB_CONTRACTOR',
    CUSTOMER: 'CUSTOMER',
    QUOTATIONS: 'QUOTATIONS',
    INVOICES: 'INVOICES',
    DOSSIERS_CEE: 'DOSSIERS_CEE',
    ORGANIZATION: 'ORGANIZATION',
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
});

export const dateUnits = Object.freeze({
    DAY: 'day',
    MILLISECONDS: 'milliseconds',
});

export const contractSectors = Object.freeze({
    RESIDENTIAL: 'Résidentiel',
    TERTIARY: 'Tertiaire',
    INDUSTRY: 'Industrie',
    AGRICULTURE: 'Agirculture',
    NETWORK: 'Réseau',
    TRANSPORT: 'Transport',
});

export const contractCoupDePouce = Object.freeze({
    ISOLANT: 'isolation',
    POMPE_CHALEUR: 'chauffage',
    EFFICIENT_RENOVATION_OF_INDIVIDUAL_HOUSES: "rénovation performante d'une maison individuelle",
    EFFICIENT_RENOVATION_OF_COLLECTIVE_RESIDENTIAL_BUILDINGS: 'rénovation performante de bâtiment résidentiel collectif',
    THERMOSTAT_WITH_HIGH_PERFORMANCE_CONTROL: 'thermostat avec régulation performante',
});

export const dossierCEEDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
    TAX_NOTICE: 'TAX_NOTICE',
    SWORN_STATEMENT: 'SWORN_STATEMENT',
    CADASTRAL_PLOT: 'CADASTRAL_PLOT',
    POST_RENOVATION_REPORT: 'POST_RENOVATION_REPORT',
    SITE_PHOTO: 'SITE_PHOTO',
    SITE_PHOTO_BEFORE_RENOVATION: 'SITE_PHOTO_BEFORE_RENOVATION',
    SITE_PHOTO_AFTER_RENOVATION: 'SITE_PHOTO_AFTER_RENOVATION',
    CADRE_CONTRIBUTION: 'CADRE_CONTRIBUTION',
    SIZING_NOTE: 'SIZING_NOTE',
    SOLAR_CERTIFICATION: 'SOLAR_CERTIFICATION',
    SOLAR_FACTOR_CALCULATION_NOTE: 'SOLAR_FACTOR_CALCULATION_NOTE',
    DOMICILIATION_CERTIFICATE: 'DOMICILIATION_CERTIFICATE',
    OTHER: 'OTHER',
    GREEN_FLAME_LABEL_SEVEN: 'GREEN_FLAME_LABEL_SEVEN',
    TECHNICAL_SHEET: 'TECHNICAL_SHEET',
    ADVANCE_PAYMENT_INVOICE: 'ADVANCE_PAYMENT_INVOICE',
    MOVING_CERTIFICATE: 'MOVING_CERTIFICATE',
    CLAIM_ASSIGNMENT: 'CLAIM_ASSIGNMENT',
});

export const taxHouseholdFormEntities = Object.freeze({
    CUSTOMER: 'CUSTOMER',
    OCCUPANT: 'OCCUPANT',
});

export const taxHouseholdFormModes = Object.freeze({
    SEARCH: 'SEARCH',
    MANUAL: 'MANUAL',
});

export const formModes = Object.freeze({
    CREATION: 'CREATION',
    EDITION: 'EDITION',
});

export const sidebarMenuRoutes = Object.freeze({
    CONTRACTS: '/contrats',
    INVOICES: '/factures',
    UPLOAD_DOCUMENTS: '/documents',
});

export const sidebarMenuTranslations = Object.freeze({
    CONTRACTS: 'Contrats obligés',
    INVOICES: 'Factures clients',
    UPLOAD_DOCUMENTS: 'Ajout de documents',
});

export const exportDataTypes = Object.freeze({
    INVOICE: 'INVOICE',
});

export const exportTypes = Object.freeze({
    CSV: 'CSV',
    PDF: 'PDF',
});

export const operationInstallationFinishes = Object.freeze({
    PLACO: 'PLACO',
    ENDUIT: 'ENDUIT',
    BARDAGE: 'BARDAGE',
    PAREMENT: 'PAREMENT',
    AUTRE: 'AUTRE',
});

export const operationInstallationFinishesTranslations = Object.freeze({
    PLACO: 'Placo',
    ENDUIT: 'Enduit',
    BARDAGE: 'Bardage',
    PAREMENT: 'Parement',
    AUTRE: 'Autre',
});

export const documentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    INVOICE: 'INVOICE',
    DOSSIERS_CEE: 'DOSSIERS_CEE',
    DELEGATEE_INVOICE: 'DELEGATEE_INVOICE',
    EMAIL: 'EMAIL',
});

export const buildingAgeTranslations = Object.freeze({
    NEW_BUILDING: 'Moins de 2 ans',
    BUILDING_IS_OLDER_THAN_TWO_YEARS: 'Plus de 2 ans',
    BUILDING_IS_OLDER_THAN_FIFTEEN_YEARS: 'Plus de 15 ans',
});

export const contractStatuses = Object.freeze({
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
    CANCELLED_FROM_BACK_OFFICE: 'CANCELLED_FROM_BACK_OFFICE',
});

export const contractStatusesTranslation = Object.freeze({
    PENDING: 'Vérification',
    CONFIRMED: 'Validé',
    REJECTED: 'Rejeté',
    EXPIRED: 'Expiré',
    CANCELLED_FROM_BACK_OFFICE: 'Annulé',
});

export const contractAmendmentStatus = Object.freeze({
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
});

export const contractAmendmentStatusTranslation = Object.freeze({
    PENDING: 'Vérification',
    CONFIRMED: 'Validé',
    REJECTED: 'Rejeté',
    CANCELLED_FROM_BACK_OFFICE: 'Annulé',
    EXPIRED: 'Expiré',
});

export const categoryTypes = Object.freeze({
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
});

export const categoryColors = Object.freeze({
    0: '#A0A5BA',
    1: '#E46526',
    2: '#6E52BC',
    3: '#00928F',
});

export const appointmentStatuses = Object.freeze({
    SENT: 'SENT',
    ACCEPTED: 'ACCEPTED',
    IN_PROGRESS: 'IN_PROGRESS',
    ENDED: 'ENDED',
    REJECTED: 'REJECTED',
    CANCELLED: 'CANCELLED',
    WITHOUT_CUSTOMER: 'WITHOUT_CUSTOMER',
});

export const fileTypes = Object.freeze({
    NEW: 'NEW',
    EXISTING: 'EXISTING',
});

export const appointmentModalTypes = Object.freeze({
    UPDATE: 'UPDATE',
    CREATE: 'CREATE',
    READ: 'READ',
});

export const appointmentFileTypes = Object.freeze({
    TECHNICIAN: 'TECHNICIAN',
    CUSTOMER: 'CUSTOMER',
});

export const reminderOptions = Object.freeze({
    ONE_HOUR: 'ONE_HOUR',
    ONE_DAY: 'ONE_DAY',
    TWO_DAYS: 'TWO_DAYS',
});

export const personStatus = Object.freeze({
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    SENT: 'SENT',
});

export const notificationTokenStatus = Object.freeze({
    ALREADY_ACCEPTED: 'ALREADY_ACCEPTED',
    ALREADY_REJECTED: 'ALREADY_REJECTED',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
});

export const appointmentNotifiedAccountsEnum = Object.freeze({
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    SMS_AND_EMAIL: 'SMS_AND_EMAIL',
    NOT_SPECIFIED: 'NOT_SPECIFIED',
});

export const statusesInfo = Object.freeze({
    SENT: {
        title: 'Envoyé',
        color: '#E46526',
    },
    ACCEPTED: {
        title: 'Accepté',
        color: '#00928F',
    },
    IN_PROGRESS: {
        title: 'En cours',
        color: '#1D3495',
    },
    ENDED: {
        title: 'Terminé',
        color: '#99A3AC',
    },
    REJECTED: {
        title: 'Rejeté',
        color: '#F53A6B',
    },
    CANCELLED: {
        title: 'Annulé',
        color: '#F53A6B',
    },
    WITHOUT_CUSTOMER: {
        title: 'Sans client',
        color: '#1D3495',
    },
});

export const tooltipVisibilityType = Object.freeze({
    HIDDEN: 'hidden',
    SHOWN: 'shown',
});

export const planningPageTypes = Object.freeze({
    APPOINTMENTS: 'appointments',
    CATEGORIES: 'categories',
    CALENDAR: 'calendar',
});

export const cancelledDocumentDocumentTypeTranslation = Object.freeze({
    CONTRACT: 'contrat ',
    CONTRACT_AMENDMENT: "l'avenant ",
    RGE_CERTIFICATE: 'certificat RGE ',
    RGE_CERTIFICATE_OF_SUBCONTRACTOR: 'certificat RGE ',
});

export const documentsToVerify = Object.freeze({
    CONTRACT: 'CONTRACT',
    RGE_CERTIFICATE: 'RGE_CERTIFICATE',
    RGE_CERTIFICATE_OF_SUBCONTRACTOR: 'RGE_CERTIFICATE_OF_SUBCONTRACTOR',
    CONTRACT_AMENDMENT: 'CONTRACT_AMENDMENT',
});

export const representativeType = Object.freeze({
    ADMINISTRATIVE_REPRESENTATIVE: 'ADMINISTRATIVE_REPRESENTATIVE',
    ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE: 'ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE',
});

export const representativeTypeTranslation = Object.freeze({
    ADMINISTRATIVE_REPRESENTATIVE: 'Administratif',
    ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE: 'Mixte',
});

export const mixedMandateStatusEnums = Object.freeze({
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED',
});

export const productSubCategories = Object.freeze({
    POMPE_CHALEUR: {
        POMPE_CHALEUR_AIR_EAU: 'POMPE_CHALEUR_AIR_EAU',
        POMPE_CHALEUR_EAU_EAU: 'POMPE_CHALEUR_EAU_EAU',
        POMPE_CHALEUR_HYBRIDE: 'POMPE_CHALEUR_HYBRIDE',
        POMPE_CHALEUR_AIR_AIR: 'POMPE_CHALEUR_AIR_AIR',
    },
    APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS: {
        POELE_GRANULES: 'POELE_GRANULES',
        POELE_BUCHES: 'POELE_BUCHES',
        FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE: 'FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE',
    },
    CHAUDIERE: {
        CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'CHAUDIERE_BIOMASSE_INDIVIDUELLE',
        CHAUDIERE_INDIVIDUELLE_A_HAUTE_PERFORMANCE_ENERGETIQUE: 'CHAUDIERE_INDIVIDUELLE',
    },
    VENTILATION: {
        SIMPLE_FLOW_VENTILATION: 'SIMPLE_FLOW_VENTILATION',
        DOUBLE_FLOW_VENTILATION: 'DOUBLE_FLOW_VENTILATION',
    },
});

export const productSubCategoriesTranslations = Object.freeze({
    POMPE_CHALEUR_AIR_EAU: 'Pompe à chaleur air/eau',
    POMPE_CHALEUR_EAU_EAU: 'Pompe à chaleur eau/eau',
    POMPE_CHALEUR_HYBRIDE: 'Pompe à chaleur hybride',
    POMPE_CHALEUR_AIR_AIR: 'Pompe à chaleur air/air',
    POELE_GRANULES: 'Poêle à granulés',
    POELE_BUCHES: 'Poêle à bûches',
    FOYER_FERME_OU_INSERT_CHEMINEE_EXTERIEURE: 'Foyer fermé, insert de cheminée intérieure',
    CHAUDIERE_BIOMASSE_INDIVIDUELLE: 'Chaudière biomasse individuelle',
    CHAUDIERE_INDIVIDUELLE: 'Chaudière individuelle à haute performance énergétique',
    SIMPLE_FLOW_VENTILATION: 'Ventilation simple flux',
    DOUBLE_FLOW_VENTILATION: 'Ventilation double flux',
});

export const productSheetMetalColors = Object.freeze({
    LIGHT: 'LIGHT',
    MEDIUM: 'MEDIUM',
    DARK: 'DARK',
    BLACK: 'BLACK',
});

export const productSheetMetalColorsTranslations = Object.freeze({
    LIGHT: 'Couleur claire',
    MEDIUM: 'Couleur moyenne',
    DARK: 'Couleur sombre',
    BLACK: 'Couleur noire',
});

export const productSheetMetalColorsTranslationsAdditonalComments = Object.freeze({
    LIGHT: 'blanc, jaune, orange, rouge clair',
    MEDIUM: 'bleu clair, gris clair, rouge sombre, vert clair',
    DARK: 'bleu vif, brun, gris moyen, vert sombre, lasure sur bois',
    BLACK: 'gris sombre, brun sombre, bleu sombre',
});

export const energyEfficiencyUnits = Object.freeze({
    THERMAL_RESISTANCE: 'm².K/W',
    THICKNESS: 'mm',
    LAMBDA: 'W/(m.K)',
});

export const fluidOptionsEnums = Object.freeze({
    EAU: 'EAU',
    EAU_GLYCOLEE: 'EAU_GLYCOLEE',
    AIR: 'AIR',
});

export const productStatusesTranslation = Object.freeze({
    PENDING: 'Vérification',
    CONFIRMED: 'Validé',
    UNVERIFIED: 'Non vérifié',
    WITHOUT_VERIFICATION: 'Sans vérification',
});

export const productStatuses = Object.freeze({
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    UNVERIFIED: 'UNVERIFIED',
    WITHOUT_VERIFICATION: 'WITHOUT_VERIFICATION',
});

export const productLayerTwoThicknesses = Object.freeze({
    FIVE: 'FIVE',
    SEVEN: 'SEVEN',
    TEN: 'TEN',
    FIFTEEN: 'FIFTEEN',
    BETWEEN_TWENTY_FIVE_AND_FIFTY: 'BETWEEN_TWENTY_FIVE_AND_FIFTY',
    BETWEEN_FIFTY_AND_ONE_HUNDRED: 'BETWEEN_FIFTY_AND_ONE_HUNDRED',
    BETWEEN_ONE_HUNDRED_AND_THREE_HUNDRED: 'BETWEEN_ONE_HUNDRED_AND_THREE_HUNDRED',
    THREE_HUNDRED: 'THREE_HUNDRED',
});

export const productLayerTwoThicknessesTranslations = Object.freeze({
    FIVE: 'Vide non ventilé 5 mm',
    SEVEN: 'Vide non ventilé 7 mm',
    TEN: 'Vide non ventilé 10 mm',
    FIFTEEN: 'Vide non ventilé 15 mm',
    BETWEEN_TWENTY_FIVE_AND_FIFTY: 'Vide non ventilé 25 ≤ d < 50',
    BETWEEN_FIFTY_AND_ONE_HUNDRED: 'Vide non ventilé 50 ≤ d < 100',
    BETWEEN_ONE_HUNDRED_AND_THREE_HUNDRED: 'Vide non ventilé 100 ≤ d < 300',
    THREE_HUNDRED: 'Vide non ventilé 300 mm',
});

export const wasteTypeEnum = Object.freeze({
    INERT_WASTE: 'INERT_WASTE',
    NOT_DANGEROUS_AND_NOT_INERT_WASTE: 'NOT_DANGEROUS_AND_NOT_INERT_WASTE',
    DANGEROUS_WASTE: 'DANGEROUS_WASTE',
    SPECIFIC_WASTE: 'SPECIFIC_WASTE',
});

export const wasteTypeTranslations = Object.freeze({
    INERT_WASTE: 'Déchets inertes',
    NOT_DANGEROUS_AND_NOT_INERT_WASTE: 'Déchets non dangereux et non intertes',
    DANGEROUS_WASTE: 'Déchets dangereux',
    SPECIFIC_WASTE: 'Déchets spécifiques',
});

export const wasteManagementUnitsEnum = Object.freeze({
    LITER: 'LITER',
    UNIT: 'UNIT',
    TONNE: 'TONNE',
    METER: 'METER',
    SQUARE_METER: 'SQUARE_METER',
    CUBIC_METER: 'CUBIC_METER',
});

export const wasteManagementUnitsTranslation = Object.freeze({
    METER: 'm',
    SQUARE_METER: 'm²',
    CUBIC_METER: 'm³',
    LITER: 'litres',
    UNIT: 'unités',
    TONNE: 'tonnes',
});

export const invoiceTypes = Object.freeze({
    ADVANCE_PAYMENT: 'ADVANCE_PAYMENT',
    CLASSIC: 'CLASSIC',
    INVOICE_CREDIT_NOTE: 'INVOICE_CREDIT_NOTE',
});

export const woodHeatingType = Object.freeze({
    COMBUSTIBLE_SOUS_FORME_GRANULES: 'COMBUSTIBLE_SOUS_FORME_GRANULES',
    COMBUSTIBLE_SOUS_AUTRE_FORME_GRANULES: 'COMBUSTIBLE_SOUS_AUTRE_FORME_GRANULES',
});

export const woodHeatingTypesLabel = Object.freeze({
    COMBUSTIBLE_SOUS_FORME_GRANULES: 'Combustible sous forme de granulés',
    COMBUSTIBLE_SOUS_AUTRE_FORME_GRANULES: 'Combustible sous une autre forme que des granulés',
});

export const productFileTypes = Object.freeze({
    GREEN_FLAME_LABEL_SEVEN: 'GREEN_FLAME_LABEL_SEVEN',
    CERTIFICATION: 'CERTIFICATION',
});

export const productFileTypesLabel = Object.freeze({
    GREEN_FLAME_LABEL_SEVEN: 'Label flamme verte 7*',
    CERTIFICATION: 'Certfication',
});

export const coupDePouceTypes = Object.freeze({
    INSULATION: 'INSULATION',
    HEATING: 'HEATING',
});

export const coupDePouceTypesTranslations = Object.freeze({
    INSULATION: 'Isolation',
    HEATING: 'Chauffage',
});

export const rackingProfiles = Object.freeze({
    M: 'M',
    L: 'L',
    XL: 'XL',
});

export const rackingProfilesLabels = Object.freeze({
    M: 'M (95% d’efficacité énergétique)',
    L: 'L (100% d’efficacité énergétique)',
    XL: 'XL (110% d’efficacité énergétique)',
});

export const delegateeInvoiceProductTypes = Object.freeze({
    DISBURSEMENT_NOTICE: 'DISBURSEMENT_NOTICE',
    SERVICE: 'SERVICE',
});

export const delegateeInvoiceProductTypesTranslations = Object.freeze({
    DISBURSEMENT_NOTICE: 'Note de débours',
    SERVICE: 'Prestation de service',
});

export const mandatMprMixteEvents = Object.freeze({
    ORGANIZATION_MANDAT_MPR_MIXTE_ACTIVATED: 'ORGANIZATION_MANDAT_MPR_MIXTE_ACTIVATED',
    ORGANIZATION_MANDAT_MPR_MIXTE_DEACTIVATED: 'ORGANIZATION_MANDAT_MPR_MIXTE_DEACTIVATED',
});

export const billingDelayPaymentTypes = Object.freeze({
    AT_INVOICE_RECEPTION: 'AT_INVOICE_RECEPTION',
    AT_DEADLINE: 'AT_DEADLINE',
});

export const functioningAlimentationTypes = Object.freeze({
    AUTOMATIC_ALIMENTATION_PELLET_BOILER: 'AUTOMATIC_ALIMENTATION_PELLET_BOILER',
    MANUEL_ALIMENTATION_FUEL_BOILER: 'MANUEL_ALIMENTATION_FUEL_BOILER',
});

export const functioningAlimentationTypesLabel = Object.freeze({
    AUTOMATIC_ALIMENTATION_PELLET_BOILER: 'Alimentation automatique – chaudières à granulés',
    MANUEL_ALIMENTATION_FUEL_BOILER: 'Alimentation manuelle – chaudières à bûches',
});

export const heatingEquipmentFunctioningTypes = Object.freeze({
    CHARCOAL: 'CHARCOAL',
    FUEL: 'FUEL',
    GAS: 'GAS',
});

export const heatingEquipmentFunctioningTypesTranslations = Object.freeze({
    CHARCOAL: 'au charbon',
    FUEL: 'au fioul',
    GAS: 'au gaz',
});

export const emailsStatuses = Object.freeze({
    MAIL_SENT: 'MAIL_SENT',
    MAIL_RECEIVED: 'MAIL_RECEIVED',
    MAIL_OPENED: 'MAIL_OPENED',
    MAIL_FAILED: 'MAIL_FAILED',
});

export const emailsStatusesTranslations = Object.freeze({
    MAIL_SENT: 'Envoyé',
    MAIL_RECEIVED: 'Reçu',
    MAIL_OPENED: 'Ouvert',
    MAIL_FAILED: 'Non remis',
});

export const brandOfProducts = Object.freeze(['ALTECH', 'ATLANTIC', 'AUER', 'DAIKIN', 'DE DIETRICH', 'HITACHI', 'MITSU BISHI', 'PANASONIC', 'THERMOR']);
export const contractNegotiators = Object.freeze({
    PRO: 'PRO',
    RENOLIB: 'RENOLIB',
});

export const dossierMaPrimeRenovDocumentTypes = Object.freeze({
    QUOTATION: 'QUOTATION',
    CADRE_CONTRIBUTION: 'CADRE_CONTRIBUTION',
    MAPRIME_RENOV_CERTIFICATE: 'MAPRIME_RENOV_CERTIFICATE',
    SOCIAL_LANDLORD_ATTESTATION: 'SOCIAL_LANDLORD_ATTESTATION',
    ANAH_GRANT_LETTER: 'ANAH_GRANT_LETTER',
    INVOICE: 'INVOICE',
    CONTROL_REPORT: 'CONTROL_REPORT',
    SITE_PHOTO: 'SITE_PHOTO',
    SITE_PHOTO_BEFORE: 'SITE_PHOTO_BEFORE',
    SITE_PHOTO_AFTER: 'SITE_PHOTO_AFTER',
});

export const dossierMaPrimeRenovDocumentTypeSubtitles = Object.freeze({
    QUOTATION: 'Signé par le client',
    MAPRIME_RENOV_CERTIFICATE: 'Signé par le client',
    SOCIAL_LANDLORD_ATTESTATION: 'Signé par le client',
    ANAH_GRANT_LETTER: 'Validée',
    INVOICE: 'Déposée sur le compte du client',
    CONTROL_REPORT: 'Conforme',
    SITE_PHOTO: 'Photo de site',
    PAYMENT_LETTER: 'Lettre de versement',
});

export const dossierMaPrimeRenovDocumentTypeTranslations = Object.freeze({
    QUOTATION: 'Devis des travaux',
    CADRE_CONTRIBUTION: 'Cadre(s) de contribution',
    MAPRIME_RENOV_CERTIFICATE: 'Mandat',
    SOCIAL_LANDLORD_ATTESTATION: 'Attestation du propriétaire bailleur',
    ANAH_GRANT_LETTER: "Lettre d'octroi",
    INVOICE: 'Facture des travaux',
    CONTROL_REPORT: 'Rapport de contrôle',
    SITE_PHOTO: 'Photo de site',
    SITE_PHOTO_AFTER: 'Photo de site',
    SITE_PHOTO_BEFORE: 'Photo de site',
    ANAH_PAYMENT_LETTER: 'Lettre de versement',
});

export const renolibEmails = Object.freeze({
    PRO_SUPPORT: 'support-pro@renolib.fr',
    TECH: 'tech@renolib.fr',
    PARTNER_SUPPORT: 'support-partenaire@renolib.fr',
});

export const quotationsAnnexes = Object.freeze({
    SWORN_STATEMENT: "Attestation sur l'honneur",
    CADRE_CONTRIBUTION: 'Cadre(s) de contribution',
    TAX_NOTICE: 'Attestation TVA',
    ADMINISTRATIVE_REPRESENTATIVE: 'Mandat administratif MPR',
    ADMINISTRATIVE_AND_FINANCIAL_REPRESENTATIVE: 'Mandat mixte MPR',
    CLAIM_ASSIGNMENT: 'Lettre de cession de créance',
    SOCIAL_LANDLORD_ATTESTATION: 'Attestation du propriétaire bailleur',
});

export const commercialDiscountType = Object.freeze({
    PERCENTAGE: 'PERCENTAGE',
    VALUE: 'VALUE',
});

export const applicationConfigurationModes = Object.freeze({
    MAINTENANCE: 'MAINTENANCE',
    ONLINE: 'ONLINE',
});
