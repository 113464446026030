export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';

export const GET_ORGANIZATION_REQUEST = 'GET_ORGANIZATION_REQUEST';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE';

export const PATCH_ORGANIZATION_REQUEST = 'PATCH_ORGANIZATION_REQUEST';
export const PATCH_ORGANIZATION_SUCCESS = 'PATCH_ORGANIZATION_SUCCESS';
export const PATCH_ORGANIZATION_FAILURE = 'PATCH_ORGANIZATION_FAILURE';

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';

export const UPDATE_ORGANIZATION_ONBOARDING_REQUEST = 'UPDATE_ORGANIZATION_ONBOARDING_REQUEST';
export const UPDATE_ORGANIZATION_ONBOARDING_SUCCESS = 'UPDATE_ORGANIZATION_ONBOARDING_SUCCESS';
export const UPDATE_ORGANIZATION_ONBOARDING_FAILURE = 'UPDATE_ORGANIZATION_ONBOARDING_FAILURE';

export const DELETE_INVITATION_REQUEST = 'DELETE_INVITATION_REQUEST';
export const DELETE_INVITATION_SUCCESS = 'DELETE_INVITATION_SUCCESS';
export const DELETE_INVITATION_FAILURE = 'DELETE_INVITATION_FAILURE';

export const REFRESH_ORGANIZATION_INFORMATION_REQUEST = 'REFRESH_ORGANIZATION_INFORMATION_REQUEST';
export const REFRESH_ORGANIZATION_INFORMATION_SUCCESS = 'REFRESH_ORGANIZATION_INFORMATION_SUCCESS';
export const REFRESH_ORGANIZATION_INFORMATION_FAILURE = 'REFRESH_ORGANIZATION_INFORMATION_FAILURE';

export const UPDATE_ORGANIZATION_STAMP_SUCCESS = 'UPDATE_ORGANIZATION_STAMP_SUCCESS';

export const DELETE_ORGANIZATION_STAMP_SUCCESS = 'UPDATE_ORGANIZATION_STAMP_SUCCESS';
